import externalTriggerAdapter from 'apps/externalTriggers/externalTriggerAdapter'
import widgetAdapter from 'apps/growthTools/models/Widget/adapter'
import keywordRuleAdapter from 'apps/keywords/models/KeywordRule/adapter'
import { quickCampaignAdapter } from 'apps/quickCampaigns/models/adapter'
import contentMapAdapter from 'apps/templates/models/ContentMap/adapter'
import { templateAdapter, installedTemplateAdapter } from 'apps/templates/models/Template/adapter'
import triggerRuleAdapter from 'apps/triggerRules/models/TriggerRule/adapter'
import type { IAPI } from 'common/core/interfaces/api'

export const fetchBotSettings = '/:currentAccountID/settings/data'
export const settingsRemoveNotification =
  '/:currentAccountID/settings/removeNotification?notification_id=:notification_id'
export const settingsFetchNotifications = '/:currentAccountID/settings/getNotifications'

export const createZap = '/:currentAccountID/zapierEvents/create?event_name=:event_name'
export const fetchZaps = '/:currentAccountID/zapierEvents/list?type=user'
export const deleteZap = '/:currentAccountID/zapierEvents/delete?event_id=:event_id'

export const fetchIntegromatWebhooks = '/:currentAccountID/integration/integromatHooks'

export const uploadAttachment = '/:currentAccountID/content/upload'

export const fetchFilterFields = '/:currentAccountID/subscribers/availableFields?:allow_user_id'
export const fetchFieldValues = '/:currentAccountID/subscribers/availableValues?:field&:q'

export const fetchSharedFlow = '/manychat/getSharedFlow?share_hash=:share_hash'

export const account = {
  endpoints: {
    connectFBChannel: '/:currentAccountID/channel/connectFb',
  },
}

export const audience: IAPI = {
  idKey: 'user_id',
  itemKey: 'user',
  listKey: 'users',
  endpoints: {
    fetch: '/:currentAccountID/subscribers/search',
    // Single subscriber actions
    importSubscribers: '/:currentAccountID/subscribers/importUnifiedSubscribers',
    createSubscriber: '/:currentAccountID/subscribers/createUnifiedSubscriber',
  },
}

export const threads: IAPI = {
  idKey: 'user_id',
  itemKey: 'thread',
  listKey: 'threads',
  endpoints: {
    fetch: () => '/:currentAccountID/im/loadThreads',
    takeThreadControl: '/:currentAccountID/im/takeThreadControl',
    fetchItem: '/:currentAccountID/im/loadThread?user_id=:id',
    bulkAction: '/:currentAccountID/im/bulk',
    checkSatisfiesFilter: ['/:currentAccountID/im/checkThreadSatisfiesFilter', { method: 'POST' }],
    updateStatus: ['/:currentAccountID/im/updateThreadStatus', { method: 'POST' }],
    markAsRead: ['/:currentAccountID/im/markThreadAsRead', { method: 'POST' }],
    markAsUnread: ['/:currentAccountID/im/markThreadAsUnread', { method: 'POST' }],
    closeAll: ['/:currentAccountID/im/closeAllThreads', { method: 'POST' }],
    setPauseForever: ['/:currentAccountID/im/setAutomationPauseForever', { method: 'POST' }],
    setPause: ['/:currentAccountID/im/setAutomationPause', { method: 'POST' }],
    clearPause: ['/:currentAccountID/im/clearAutomationPause', { method: 'POST' }],
    setAssignment: ['/:currentAccountID/im/setAssignment', { method: 'POST' }],
    fetchOtns: '/:currentAccountID/subscribers/details?user_id=:id',
    fetchThreadCounters: '/:currentAccountID/im/getLiveChatCounters',
  },
}

export const messages: IAPI = {
  idKey: 'message_id',
  listKey: 'messages',
  endpoints: {
    fetch: '/:currentAccountID/im/loadMessages?limit=50&user_id=:threadId',
    send: ['/:currentAccountID/im/sendMessage', { method: 'POST' }],
    sendInstagram: ['/:currentAccountID/im/sendInstagramMessage', { method: 'POST' }],
    sendTelegram: ['/:currentAccountID/im/sendTelegramMessage', { method: 'POST' }],
    sendTiktok: ['/:currentAccountID/im/sendTiktokMessage', { method: 'POST' }],
    createNote: ['/:currentAccountID/im/createNote', { method: 'POST' }],
    sendFlow: '/:currentAccountID/im/sendFlow',
    sendWhatsAppMessage: [
      '/:currentAccountID/im/sendWhatsAppMessage?clear_question_context=true',
      { method: 'POST' },
    ],
    sendSMS: ['/:currentAccountID/im/sendSMS', { method: 'POST' }],
  },
}

export const liveChatSearch: IAPI = {
  idKey: 'message_id',
  listKey: 'messages',
  endpoints: {
    fetch: '/:currentAccountID/im/searchMessage',
  },
}

export const contentMap: IAPI = {
  itemKey: 'map',
  import: contentMapAdapter,
  endpoints: {
    fetchItem: '/:id/map/get',
    getFolders: '/:id/cms/getFolders',
  },
}

export const chatAdmins: IAPI = {
  idKey: 'user_id',
  listKey: 'admins',
  endpoints: {
    fetch: '/:currentAccountID/im/admins',
  },
}

export const keywords: IAPI = {
  idKey: 'rule_id',
  itemKey: 'rule',
  listKey: 'rules',
  import: keywordRuleAdapter,
  endpoints: {
    fetch: '/:currentAccountID/keywords/list',
    fetchItem: '/:currentAccountID/keywords/get?rule_id=:id',
    create: ['/:currentAccountID/keywords/create', { method: 'POST' }],
    save: ['/:currentAccountID/keywords/update', { method: 'POST' }],
    saveStatus: '/:currentAccountID/keywords/setStatus?rule_id=:id&status=:status',
    delete: '/:currentAccountID/keywords/delete?rule_id=:id',
    sort: ['/:currentAccountID/keywords/sort', { method: 'POST' }],
    setFlow: '/:currentAccountID/keywords/setFlow',
    unsetFlow: '/:currentAccountID/keywords/unsetFlow',
    migrateActions: '/:currentAccountID/keywords/migrateActions',
  },
}

export const tagsFolders: IAPI = {
  idKey: 'folder_id',
  itemKey: 'folder',
  listKey: 'folders',
  endpoints: {
    fetch: ['/:currentAccountID/folders/list', { method: 'POST' }],
    create: ['/:currentAccountID/folders/create', { method: 'POST' }],
    rename: '/:currentAccountID/folders/rename',
    delete: ['/:currentAccountID/folders/delete', { method: 'POST' }],
  },
}

export const targetPageTags: IAPI = {
  idKey: 'tag_id',
  itemKey: 'tag',
  listKey: 'tags',
  endpoints: {
    fetch: '/:pageId/tags/list?type=user',
  },
}

export const targetPageCustomEvents: IAPI = {
  idKey: 'event_id',
  itemKey: 'event',
  listKey: 'list',
  endpoints: {
    fetch: '/:pageId/customEvents/list',
  },
}

export const userFields: IAPI = {
  idKey: 'field_id',
  itemKey: 'field',
  listKey: 'fields',
  endpoints: {
    fetch: '/:currentAccountID/customFields/list?active_only=:active_only',
    create: ['/:currentAccountID/customFields/create', { method: 'POST' }],
    save: ['/:currentAccountID/customFields/update', { method: 'POST' }],
    archive: '/:currentAccountID/customFields/archive?field_id=:id',
    restore: '/:currentAccountID/customFields/restore?field_id=:id',
    delete: ['/:currentAccountID/customFields/delete', { method: 'POST' }],
    setSubscriberField: ['/:currentAccountID/subscribers/setCustomField', { method: 'POST' }],
    setSystemField: '/:currentAccountID/subscribers/setSystemField',
    getEntitiesList: ['/:currentAccountID/customFields/getUsesList'],
    archiveBulk: ['/:currentAccountID/customFields/archiveBulk'],
    deleteBulk: ['/:currentAccountID/customFields/deleteBulk'],
    restoreBulk: ['/:currentAccountID/customFields/restoreBulk'],
  },
}

export const userFieldFolders: IAPI = {
  idKey: 'folder_id',
  itemKey: 'folder',
  listKey: 'folders',
  endpoints: {
    fetch: ['/:currentAccountID/folders/list', { method: 'POST' }],
    create: ['/:currentAccountID/folders/create', { method: 'POST' }],
    rename: ['/:currentAccountID/folders/rename', { method: 'POST' }],
    delete: ['/:currentAccountID/folders/delete', { method: 'POST' }],
  },
}

export const globalFieldFolders: IAPI = {
  idKey: 'folder_id',
  itemKey: 'folder',
  listKey: 'folders',
  endpoints: {
    fetch: ['/:currentAccountID/folders/list', { method: 'POST' }],
    create: ['/:currentAccountID/folders/create', { method: 'POST' }],
    rename: ['/:currentAccountID/folders/rename', { method: 'POST' }],
    delete: ['/:currentAccountID/folders/delete', { method: 'POST' }],
  },
}

export const targetPageUserFields: IAPI = {
  idKey: 'field_id',
  itemKey: 'field',
  listKey: 'fields',
  endpoints: {
    fetch: '/:pageId/customFields/list?active_only=:active_only',
  },
}

export const targetPageGlobalFields: IAPI = {
  idKey: 'field_id',
  itemKey: 'field',
  listKey: 'fields',
  endpoints: {
    fetch: '/:pageId/globalFields/list?active_only=:active_only',
  },
}

export const targetPageOtns: IAPI = {
  idKey: 'reason_id',
  itemKey: 'reason',
  listKey: 'reasons',
  endpoints: {
    fetch: '/:pageId/oneTimeNotifyReason/list?active_only=true',
  },
}

export const customEvents: IAPI = {
  idKey: 'event_id',
  itemKey: 'custom_event',
  listKey: 'list',
  endpoints: {
    fetch: '/:currentAccountID/customEvents/list', // ?active_only=:active_only
    create: ['/:currentAccountID/customEvents/create', { method: 'POST' }],
    update: '/:currentAccountID/customEvents/update',
    save: ['/:currentAccountID/customEvents/update', { method: 'POST' }],
    archive: ['/:currentAccountID/customEvents/archive', { method: 'POST' }],
    restore: ['/:currentAccountID/customEvents/restore', { method: 'POST' }],
    delete: '/:currentAccountID/customEvents/delete',
    setValue: ['/:currentAccountID/customEvents/changeValue', { method: 'POST' }],
    getEntitiesList: ['/:currentAccountID/customEvents/getUsesList'],
  },
}

export const settings: IAPI = {
  itemKey: 'settings',
  endpoints: {
    fetchItem: '/:currentAccountID/settings/data',
    setGreeting: '/:currentAccountID/settings/setGreeting',
    setUrlShortener: '/:currentAccountID/settings/setUrlShortener',
    setBodyUrlShortener: '/:currentAccountID/settings/setBodyUrlShortener',
    setGate: '/:currentAccountID/settings/changeGate',
    changeLcThreadsFilter: '/:currentAccountID/settings/changeLcThreadsFilter',
    setNotificationEmail: '/:currentAccountID/settings/setNotificationEmail',
    setNotificationPhone: '/:currentAccountID/settings/setNotificationPhone',
    resendConfirmationNotificationEmail: [
      '/:currentAccountID/settings/resendConfirmationNotificationEmail',
      { method: 'POST' },
    ],
    resetUnverifiedNotificationEmail: [
      '/:currentAccountID/settings/resetUnverifiedNotificationEmail',
      { method: 'POST' },
    ],
    disableUserInput: '/:currentAccountID/settings/composerInputDisable',
    setPageName: '/:currentAccountID/settings/setPageName',
    setNotifyAdmin: '/:currentAccountID/settings/setNotifyAdmin',
    setWidgetLocale: '/:currentAccountID/settings/setWidgetLocale',
    getListLocales: '/:currentAccountID/settings/getListLocales',
    setLivechatSettings: '/:currentAccountID/settings/setLivechatSettings',
    setLivechatAccountSettings: '/:currentAccountID/settings/setLivechatAccountSettings',
    getPageExtendedData: '/:currentAccountID/settings/pageExtendedData',
    getIsOptedIn: '/:currentAccountID/settings/isOptedIn',
    getListTimezones: '/:currentAccountID/settings/getListTimezones',
    setTimezone: '/:currentAccountID/settings/setTimezone',
    suspendNotifyAdminByMessenger: '/:currentAccountID/settings/suspendNotifyAdminByMessenger',
    setMobileNotifyAdminPushEnabled: '/:currentAccountID/settings/setMobileNotifyAdminPushEnabled',
    setSkipFlowTemplatesModal: '/:currentAccountID/settings/setSkipFlowTemplatesModal',

    // Users section
    // Payments
    disconnectStripe: '/:currentAccountID/settings/disconnectStripe',
    setCurrency: '/:currentAccountID/settings/setCurrency',
    setPaymentNotifySettings: '/:currentAccountID/settings/setPaymentNotifySettings',
    setStripeChargeEmailNotification:
      '/:currentAccountID/settings/setStripeChargeEmailNotification',
    getFbPermissions: '/:currentAccountID/settings/fbPagePermissionsChecker',

    // growth tools
    enableWidgetBranding: '/:currentAccountID/settings/enableWidgetBranding',
    setAudienceOnlyActive: '/:currentAccountID/settings/setAudienceOnlyActive',
    // subscription permission
    messagingFeatureReview: '/:currentAccountID/settings/messagingFeatureReview',
    // persona api
    usePersona: '/:currentAccountID/settings/usePersona',
    uploadPersonaAvatar: '/:currentAccountID/settings/UploadPersonaApiAvatar',
    savePersona: '/:currentAccountID/settings/setPersonaApiData',

    // disconnect account
    generateDisconnectUrl: '/:currentAccountID/settings/generateDisconnectUrl',
    prepareFbPageRemove: '/:currentAccountID/channel/prepareFbPageDisconnect',
    confirmFbPageRemove: '/:currentAccountID/channel/confirmFbPageDisconnect',
    prepareIgAccountRemove: '/:currentAccountID/channel/prepareIgAccountDisconnect',
    confirmIgAccountRemove: '/:currentAccountID/channel/confirmIgAccountDisconnect',
    prepareTgAccountRemove: '/:currentAccountID/channel/prepareTgAccountDisconnect',
    confirmTgAccountRemove: '/:currentAccountID/channel/confirmTgAccountDisconnect',
    prepareTiktokAccountRemove: '/:currentAccountID/channel/prepareTiktokAccountDisconnect',
    confirmTiktokAccountRemove: '/:currentAccountID/channel/confirmTiktokAccountDisconnect',
    //switch facebook channel for unified acc
    switchFacebookChannel: '/:currentAccountID/settings/switchFacebookChannel',
    readAllPolicyEnforcementNotifications:
      '/:currentAccountID/settings/readAllPolicyEnforcementNotifications',
    fetchPricingLimitInfo: '/:currentAccountID/settings/pricingLimitInfo',

    // business domains
    addBusinessEmail: '/:currentAccountID/settings/addBusinessEmail',
    updateBusinessEmail: '/:currentAccountID/settings/updateBusinessEmail',
    verifyBusinessDomain: '/:currentAccountID/settings/verifyBusinessDomain',
    getBusinessEmails: '/:currentAccountID/settings/getBusinessEmails',
    removeBusinessEmail: '/:currentAccountID/settings/removeBusinessEmail',
    getBusinessEmailInfo:
      '/businessEmail/getBusinessEmailInfo?email_verification_code=:email_verification_code',
    confirmBusinessEmail: '/businessEmail/confirmBusinessEmail',
    sendVerifyAccountBusinessEmail: '/:currentAccountID/settings/sendVerifyAccountBusinessEmail',
    getConnectedInstagramAccount: '/:currentAccountID/settings/getConnectedInstagramAccount',
    connectInstagramAccount: '/:currentAccountID/settings/connectInstagramAccount',
    toggleInstagramChannel: '/:currentAccountID/settings/toggleInstagramChannel',
    setWhatsAppUrlShortener: '/:currentAccountID/settings/setWhatsAppUrlShortener',
  },
}

export const widgets: IAPI = {
  idKey: 'widget_id',
  itemKey: 'widget',
  listKey: 'widgets',
  import: widgetAdapter,
  endpoints: {
    fetch: '/:currentAccountID/growth-tools/list',
    fetchItem: '/:currentAccountID/growth-tools/loadWidget?widget_id=:id',
    create: [
      '/:currentAccountID/growth-tools/createWidget?widget_type=:type&name=:name&ns=:ns',
      { method: 'POST' },
    ],
    save: ['/:currentAccountID/growth-tools/setWidget', { method: 'POST' }],
    saveStatus: [
      '/:currentAccountID/growth-tools/setDraftStatus?widget_id=:id&status=:status',
      { method: 'POST' },
    ],
    delete: '/:currentAccountID/growth-tools/deleteWidget?widget_id=:id',
    copy: '/:currentAccountID/growth-tools/copyWidget?widget_id=:id',
  },
}

export const segments: IAPI = {
  idKey: 'segment_id',
  listKey: 'segments',
  endpoints: {
    fetch: '/:currentAccountID/subscribers/segments',
  },
}

export const forms: IAPI = {
  idKey: 'content_id',
  itemKey: 'form',
  listKey: 'forms',
  endpoints: {
    fetch: '/:currentAccountID/forms/getList',
    fetchItem: '/:currentAccountID/forms/getAnswers?content_id=:id&limit=1',
    markAsRead: ['/:currentAccountID/forms/markAsRead', { method: 'POST' }],
  },
}

export const answers: IAPI = {
  idKey: 'id',
  itemKey: 'answer',
  listKey: 'answers',
  endpoints: {
    fetch: '/:currentAccountID/forms/getAnswers?content_id=:id',
    prepareExport: '/:currentAccountID/forms/prepareAnswersExport',
    download: '/forms/downloadAnswersExport?export_id=:exportId&content_id=:contentId',
  },
}

export const subscribers: IAPI = {
  idKey: 'user_id',
  itemKey: 'user',
  listKey: 'users',
  endpoints: {
    fetch: '/:currentAccountID/subscribers/search',
    countSubscribers: '/:currentAccountID/subscribers/count',
  },
}

export const homeTab: IAPI = {
  endpoints: {
    getQuestionnaireAnswers: '/:currentAccountID/homeTab/getQuestionnaireAnswers',
    addEvents: '/:currentAccountID/homeTab/addEvents',
    getEvents: '/:currentAccountID/homeTab/getEvents',
    applyIgOnboardingCall: '/:currentAccountID/homeTab/applyIgOnboardingCall',
  },
}

export const metricsDashboard: IAPI = {
  endpoints: {
    earnedChart: '/:currentAccountID/eventsChart/earnedChart',
    eventChart: '/:currentAccountID/eventsChart/eventChart',
    eventList: '/:currentAccountID/eventsChart/eventList',
    getUsedCurrenciesList: '/:currentAccountID/eventsChart/usedCurrencyList',
  },
}

export const broadcasting: IAPI = {
  endpoints: {
    loadScheduled: '/:currentAccountID/broadcasting/loadScheduled',
    loadHistory: '/:currentAccountID/broadcasting/loadHistory',
    loadDrafts: '/:currentAccountID/broadcasting/loadDrafts',
    load: '/:currentAccountID/broadcasting/load',
    delete: '/:currentAccountID/broadcasting/delete',
    create: '/:currentAccountID/broadcasting/create',
    update: '/:currentAccountID/broadcasting/update',
    copy: '/:currentAccountID/broadcasting/copy',
    schedule: '/:currentAccountID/broadcasting/schedule',
    unschedule: '/:currentAccountID/broadcasting/unschedule',
    interrupt: '/:currentAccountID/broadcasting/interrupt',
    forceSending: '/:currentAccountID/broadcasting/forceSending',
  },
}

export const sponsoredMessages: IAPI = {
  endpoints: {
    loadTableData:
      '/:currentAccountID/ads/getTableData?with_growth_tools=true&with_ad_set_drafts=true&with_ad_accounts=true&with_ads=true&with_stats=true&with_currencies=true',
    enablePageAds: ['/:currentAccountID/ads/enable', { method: 'POST' }],
    loadAdAccounts: '/:currentAccountID/ads/allAdAccounts',
    getAdAccountCurrency: '/ads/fb/adAccount/getCurrency?ad_account_id=:ad_account_id',
    createAdCampaign: '/ads/fb/campaign/create',
    getAdCampaigns: '/:currentAccountID/ads/allCampaigns?ad_account_id=:ad_account_id',
    updateCampaignName: '/ads/fb/campaign/update',
    createAdSet: '/ads/fb/adset/create',
    getAdSets: '/:currentAccountID/ads/allAdSets?campaign_id=:campaign_id',
    updateAdSet: '/ads/fb/adset/update',
    fetchAdSetDraft: '/ads/fb/adset/getDraft?ad_set_draft_id=:ad_set_draft_id&with_ad_set=true',
    fetchAdSet: '/ads/fb/adset/get?ad_set_id=:ad_set_id&with_ad_set_draft=true',
    publishAdSet: '/ads/fb/adset/publish',
    getCustomAudience: '/ads/fb/adAccount/getCustomAudience?custom_audience_id=:custom_audience_id',
    createCustomAudience: '/ads/fb/adAccount/createCustomAudience',
    deleteCustomAudience: '/ads/fb/adAccount/deleteCustomAudience',
    addUsersToCustomAudience: '/ads/fb/adAccount/addUsersToCustomAudience',
    createAd: '/:currentAccountID/ads/growthTool/create',
    fetchAdDraft: '/:currentAccountID/ads/growthTool/get?id=:ad_draft_id',
    saveAdDraft: '/:currentAccountID/ads/growthTool/saveDraft',
    publishAd: '/:currentAccountID/ads/growthTool/publish',
    deleteAd: '/:currentAccountID/ads/growthTool/delete',
    duplicateAd: '/:currentAccountID/ads/growthTool/clone',
    updateAdName: '/:currentAccountID/ads/growthTool/updateTitle',
  },
}

export const cms: IAPI = {
  endpoints: {
    getObject: '/:currentAccountID/cms/getObject',
    exportFlow: '/:currentAccountID/cms/exportWithDependencies',
    importSharedFlow: `/:pageId/flow/importSharedFlow`,
    moveTrigger: '/:currentAccountID/flow/moveTrigger',
    fetchFlowDependencies: '/:currentAccountID/cms/getFlowDependencies?flow_ns=:flowId',
    overallList: '/:currentAccountID/flow/list',
    bulkAction: '/:currentAccountID/cms/bulk',
  },
}

export const defaultReply: IAPI = {
  endpoints: {
    switch: '/:currentAccountID/defaultReply/switch',
    getData: '/:currentAccountID/defaultReply/getData',
    getAllData: '/:currentAccountID/defaultReply/getAllData',
    replaceFlow: '/:currentAccountID/defaultReply/replaceFlow',
    removeFlow: '/:currentAccountID/defaultReply/removeFlow',
    replaceFlowAndActivate: '/:currentAccountID/defaultReply/replaceFlowBe',
    changeType: '/:currentAccountID/defaultReply/changeType',
    changeSource: '/:currentAccountID/defaultReply/changeSource',
    unsetDefaultReply: '/:currentAccountID/defaultReply/unset',
  },
}

export const flow: IAPI = {
  endpoints: {
    getFlow: '/:currentAccountID/flow/getData',
    getFlowData: '/:currentAccountID/flow/getFlowData',
    setFlowName: '/:currentAccountID/flow/setName',
    patchFlowDraft: '/:currentAccountID/flow/patchDraft',
    setFlowDraft: '/:currentAccountID/flow/setDraft',
    discardFlowChanges: '/:currentAccountID/flow/discardChanges',
    publishFlow: '/:currentAccountID/flow/publish',
    setFlowShared: '/:currentAccountID/flow/setShared',
    setFlowSharedCloning: '/:currentAccountID/flow/setSharedCloning',
    setQuickCampaignId: '/:currentAccountID/flow/setQuickCampaignId',
    createFirstRecurringFlowWithMessage: '/:currentAccountID/flow/createDefaultTopicFlow',
  },
}

export const content: IAPI = {
  endpoints: {
    getContent: '/:currentAccountID/content/data',
    getContentWithStats: '/:currentAccountID/content/view',
    sendPreview: '/:currentAccountID/content/preview',
    getPreviewRefURL: '/:currentAccountID/content/previewRefUrl',
    testRequest: '/:currentAccountID/content/:entity',
    getUserVariables: '/:currentAccountID/content/getUserVariables',
  },
}

// Template
export const botTemplate: IAPI = {
  endpoints: {
    fetch: '/template/:template_hash/getData',
    install: '/template/:template_hash/process',
    fetchWelcomeMessage: '/template/:template_hash/getWelcomeMessage',
    fetchMainMenu: '/template/:template_hash/getMainMenu',
    fetchDefaultReply: '/template/:template_hash/getDefaultReply',
  },
}

export const templates: IAPI = {
  idKey: 'template_id',
  itemKey: 'data',
  listKey: 'templates',
  import: templateAdapter,
  endpoints: {
    fetch: '/template/list',
    fetchItem: '/template/getData?template_id=:id',
    fetchInstalled: '/:currentAccountID/templates/list',
    fetchInstalledItem: '/templates/data?template_installation_id=:template_installation_id',
    create: ['/template/create', { method: 'POST' }],
    delete: ['/template/delete', { method: 'POST' }],
    deleteInstalled: '/:currentAccountID/templates/uninstall',
    saveContent: ['/template/update', { method: 'POST' }],
    saveTitle: ['/template/setTitle', { method: 'POST' }],
    saveDescription: ['/template/setDescription', { method: 'POST' }],
    saveShare: ['/template/setShare', { method: 'POST' }],
    uploadAvatar: '/template/uploadImage',
    updateAvatar: ['/template/updateAvatar', { method: 'POST' }],
    setShareType: '/template/setShareType',
    fetchSingleuseLink: '/template/generateLink',
    editInstalledTemplateProtection: '/templateInstallation/setIsProtected',
  },
}

export const templateInstallations: IAPI = {
  idKey: 'template_installation_id',
  itemKey: 'templateInstallation',
  listKey: 'templates',
  endpoints: {
    fetch: '/template/installationList',
    activateForPage: [
      '/template/enableTemplateForPage?template_installation_id=:id&page_id=:page_id',
      { method: 'POST' },
    ],
    deactivate: [
      '/template/disableTemplateForPage?template_installation_id=:id&page_id=:page_id',
      { method: 'POST' },
    ],
    untrackTemplate: [
      '/template/untrackTemplate?template_installation_id=:id&page_id=:page_id',
      { method: 'POST' },
    ],
    unprotectTemplate: [
      '/template/unprotectTemplate?template_installation_id=:id&page_id=:page_id',
      { method: 'POST' },
    ],
  },
}

export const installedTemplates: IAPI = {
  idKey: 'template_installation_id',
  itemKey: 'item',
  listKey: 'templates',
  import: installedTemplateAdapter,
  endpoints: {
    fetch: '/:currentAccountID/templates/list',
    fetchItem: '/:currentAccountID/templates/data?template_installation_id=:id',
  },
}

export const storeTemplates: IAPI = {
  idKey: 'template_id',
  listKey: 'templates',
  itemKey: 'template',
  endpoints: {
    fetch: '/templateStore/templatesList?category_id=:id&pro_status=:status',
    fetchItem: '/templateStore/getTemplate?template_id=:id',
    install: '/templateStore/:template_id/install',
    fetchWelcomeMessage: '/templateStore/getWelcomeMessage?template_id=:id',
    fetchMainMenu: '/templateStore/getMainMenu?template_id=:id',
    fetchDefaultReply: '/templateStore/getDefaultReply?template_id=:id',
  },
}

export const storeCategories: IAPI = {
  listKey: 'categories',
  endpoints: {
    fetch: '/templateStore/categoriesList',
  },
}

export const storeTemplateIntegrations: IAPI = {
  listKey: 'integrations',
  idKey: 'integration_id',
  endpoints: {
    fetch: '/templateStore/integrationsList',
  },
}

export const storeRelatedTemplates: IAPI = {
  idKey: 'template_id',
  listKey: 'templates',
  endpoints: {
    fetch: '/templateStore/getRelatedTemplates?template_id=:id',
  },
}

// Applications
export const applications: IAPI = {
  idKey: 'app_id',
  itemKey: 'app',
  listKey: 'apps',
  endpoints: {
    fetch: '/application/list',
    fetchItem: '/application/get?app_id=:id',
    fetchAccounts: '/application/accounts?app_id=:id',
    create: '/application/create',
    update: '/application/update',
    install: '/appStore/install',
    uninstall: '/application/uninstall',
    fetchSettings: '/application/getSettings?account_id=:accountId&app_id=:applicationId',
    updateSettings: '/application/updateSettings',
    publish: '/application/publish',
    getReport: '/application/getReport?app_id=:applicationId&version=:version',
    uploadAvatar: '/application/uploadImage',
  },
}

// Applications Store
export const applicationsStore: IAPI = {
  endpoints: {
    fetchApplicationByHash: '/appStore/get?app_hash=:hash',
  },
}

// AccountApplications
export const accountApplications: IAPI = {
  endpoints: {
    fetchApplicationAction:
      '/:currentAccountID/accountApps/getAppAction?app_id=:applicationId&action_name=:actionName',
    fetchApplicationSource:
      '/:currentAccountID/accountApps/loadAppSource?app_id=:applicationId&source_name=:sourceName',
  },
}

// InstalledApplications
export const installedApplications: IAPI = {
  endpoints: {
    fetchInstalledApps: '/:currentAccountID/accountApps/getInstalledApps',
    fetchApplicationsTriggers: '/:currentAccountID/accountApps/getTriggers',
    getAppActions: '/:currentAccountID/accountApps/getAppActions?app_id=:applicationId',
    fetchUsesList: '/:currentAccountID/accountApps/getUsesList?app_id=:applicationId',
    fetchSettingsForm: '/application/getSettingsForm?account_id=:accountId&app_id=:applicationId',
    getChangeLogs: '/:currentAccountID/accountApps/getChangeLogs?app_id=:applicationId',
    updateVersion: '/:currentAccountID/accountApps/updateVersion',
  },
}

export const pages: IAPI = {
  listKey: 'pages',
  endpoints: {
    fetch: '/auth/getPages',
  },
}

export const orders: IAPI = {
  idKey: 'id',
  listKey: 'orders_list',
  endpoints: {
    fetch: '/:currentAccountID/order/getOrdersList',
  },
}

export const integrations: IAPI = {
  endpoints: {
    fetch: '/:currentAccountID/integration/getIntegrations',
    fetchIntegration: '/:currentAccountID/integration/getIntegration?type=:type',
    connectIntegration: '/integration/connectIntegration?type=:type',
    connectIntegrationByToken: '/:currentAccountID/integration/connectIntegrationByToken',
    disconnectIntegration: '/:currentAccountID/integration/disconnectIntegration',
    hubspot: {
      setAccountLinkField: '/:currentAccountID/integration/hubspot/setAccountLinkField',
      getForms: '/:currentAccountID/integration/hubspot/getForms',
      getFormById: '/:currentAccountID/integration/hubspot/getFormById?form_id=:form_id',
      getFormFields: '/:currentAccountID/integration/hubspot/getFormFields?form_id=:form_id',
      getContactFields: '/:currentAccountID/integration/hubspot/getContactFields',
      clearAccountLinkField: [
        '/:currentAccountID/integration/hubspot/clearAccountLinkField',
        { method: 'POST' },
      ],
    },
    convertkit: {
      setAccountLinkField: '/:currentAccountID/integration/convertkit/setAccountLinkField',
      clearAccountLinkField: [
        '/:currentAccountID/integration/convertkit/clearAccountLinkField',
        { method: 'POST' },
      ],
      getForms: '/:currentAccountID/integration/convertkit/getForms',
      getSequences: '/:currentAccountID/integration/convertkit/getSequences',
      getTags: '/:currentAccountID/integration/convertkit/getTags',
      getFields: '/:currentAccountID/integration/convertkit/getFields',
    },
    googleSheets: {
      getSpreadsheets: '/:currentAccountID/integration/googleSheets/getSpreadsheets',
      getWorksheets:
        '/:currentAccountID/integration/googleSheets/getSheets?spreadsheet_id=:spreadsheet_id',
      getColumnHeaders:
        '/:currentAccountID/integration/googleSheets/getColumnHeaders?spreadsheet_id=:spreadsheet_id&sheet_title=:sheet_title',
    },
    activeCampaign: {
      getLists: '/:currentAccountID/integration/activeCampaign/getLists',
      getFields: '/:currentAccountID/integration/activeCampaign/getFields',
    },
    klaviyo: {
      getLists: '/:currentAccountID/integration/klaviyo/getLists',
      getFields: '/:currentAccountID/integration/klaviyo/getFields',
    },
    mailchimp: {
      setAccountLinkField: '/:currentAccountID/integration/mailChimp/setIdentityField',
      clearAccountLinkField: [
        '/:currentAccountID/integration/mailChimp/clearIdentityField',
        { method: 'POST' },
      ],
      getLists: '/:currentAccountID/integration/mailChimp/getLists',
    },
  },
}

export const triggerRules: IAPI = {
  idKey: 'rule_id',
  itemKey: 'trigger_rule',
  listKey: 'trigger_rules',
  import: triggerRuleAdapter,
  endpoints: {
    fetch: '/:currentAccountID/triggerRule/list',
    fetchItem: '/:currentAccountID/triggerRule/get?rule_id=:id',
    create: ['/:currentAccountID/triggerRule/create', { method: 'POST' }],
    copy: ['/:currentAccountID/triggerRule/copy', { method: 'POST' }],
    delete: ['/:currentAccountID/triggerRule/delete', { method: 'POST' }],
    restore: ['/:currentAccountID/triggerRule/restore', { method: 'POST' }],
    changeStatus: ['/:currentAccountID/triggerRule/changeStatus', { method: 'POST' }],
    save: ['/:currentAccountID/triggerRule/update', { method: 'POST' }],
    setTitle: ['/:currentAccountID/triggerRule/setTitle', { method: 'POST' }],
  },
}

export const externalTriggers: IAPI = {
  idKey: 'external_trigger_id',
  itemKey: 'external_trigger',
  import: externalTriggerAdapter,
  endpoints: {
    fetchItem: '/:currentAccountID/externalTrigger/get?external_trigger_id=:id',
    create: ['/:currentAccountID/externalTrigger/create'],
    save: ['/:currentAccountID/externalTrigger/update', { method: 'POST' }],
    delete: ['/:currentAccountID/externalTrigger/delete', { method: 'POST' }],
    setTitle: ['/:currentAccountID/externalTrigger/setTitle', { method: 'POST' }],
    changeStatus: ['/:currentAccountID/externalTrigger/changeStatus', { method: 'POST' }],
  },
}

export const ads: IAPI = {
  endpoints: {
    enable: '/ads/enable',
    openFeature: ['/ads/openFeature', { method: 'POST' }],
    upload: '/ads/fb/file/upload',
    getVideo: '/ads/fb/file/getVideo',
    updateAdStatus: '/ads/fb/ad/updateStatus',
    enablePage: ['/:currentAccountID/ads/enable', { method: 'POST' }],
    getTableData:
      '/:currentAccountID/ads/getTableData?with_growth_tools=true&with_ad_set_drafts=true&with_ad_accounts=true&with_campaigns=true&with_ad_sets=true&with_ads=true&with_stats=true&with_currencies=true',
    getCampaignTree: '/:currentAccountID/ads/getCampaignTree?campaign_id=:campaign_id',
    getAccountsInfo: '/:currentAccountID/ads/fullInfo?needed_accounts=true&needed_campaigns=true',
    getAccountInfo:
      '/:currentAccountID/ads/fullInfo?ad_account_id=:ad_account_id&needed_campaigns=true',
    getAd: '/ads/fb/ad/get?ad_id=:ad_id',
    allAdAccounts: '/:currentAccountID/ads/allAdAccounts',
    allCampaigns: '/:currentAccountID/ads/allCampaigns?ad_account_id=:ad_account_id',
    allAdSets: '/:currentAccountID/ads/allAdSets?campaign_id=:campaign_id',
    getCurrency: '/ads/fb/adAccount/getCurrency?ad_account_id=:ad_account_id',
    createCustomAudience: '/ads/fb/adAccount/createCustomAudience',
    deleteCustomAudience: '/ads/fb/adAccount/deleteCustomAudience',
    checkAddingAbility: '/ads/fb/adAccount/checkAddingAbility',
    createLookalikeAudience: '/ads/fb/adAccount/createLookalikeAudience',
    addUsersToCustomAudience: '/ads/fb/adAccount/addUsersToCustomAudience',
    getCustomAudiences: '/ads/fb/adAccount/getListCustomAudiences?ad_account_id=:ad_account_id',
    getCustomAudienceUserActionNames:
      '/:currentAccountID/ads/getCustomAudienceUserActionNames?ad_account_id=:ad_account_id&custom_audience_id=:custom_audience_id&user_id=:user_id',
    getLookalikeSourcePages: '/ads/fb/adAccount/getLookalikeSourcePages?business_id=:business_id',
  },
}

export const adCampaigns: IAPI = {
  endpoints: {
    create: '/ads/fb/campaign/create',
    save: '/ads/fb/campaign/update',
    updateStatus: '/ads/fb/campaign/updateStatus',
    delete: '/ads/fb/campaign/delete',
    get: '/ads/fb/campaign/get?campaign_id=:campaignId',
    fetchItem: '/ads/fb/campaign/get?campaign_id=:id',
  },
}

export const adSets: IAPI = {
  endpoints: {
    getPublished: '/ads/fb/adset/get?ad_set_id=:ad_set_id&with_ad_set_draft=true',
    getDraft: '/ads/fb/adset/getDraft?ad_set_draft_id=:ad_set_draft_id&with_ad_set=true',
    create: ['/ads/fb/adset/create', { method: 'POST' }],
    save: ['/ads/fb/adset/update', { method: 'POST' }],
    publish: '/ads/fb/adset/publish',
    estimate: '/ads/fb/adset/estimate',
    updateStatus: '/ads/fb/adset/updateStatus',
    discardDraft: '/ads/fb/adset/discardDraft',
    delete: '/ads/fb/adset/delete',
    targeting: {
      searchLocations:
        '/ads/fb/adset/searchTarget?type=adgeolocation&q=:query&params[limit]=:limit',
      searchCountyies:
        '/ads/fb/adset/searchTarget?type=adgeolocation&q=:query&params[limit]=:limit&&params[location_types]=["country","country_group"]',
      searchDetailedTargeting:
        '/ads/fb/adset/getTargetingSearch?ad_account_id=:ad_account_id&q=:query',
      getLocationsMeta:
        '/ads/fb/adset/searchTarget?type=adgeolocationmeta' +
        '&params[countries]=:countries' +
        '&params[regions]=:regions' +
        '&params[cities]=:cities' +
        '&params[zips]=:zips' +
        '&params[places]=:places' +
        '&params[custom_locations]=:custom_locations' +
        '&params[geo_markets]=:geo_markets' +
        '&params[electoral_districts]=:electoral_districts' +
        '&params[country_groups]=:country_groups',
      getLocales: '/ads/fb/adset/searchTarget?type=adlocale&q=&params[limit]=1000',
    },
    getPlatforms:
      '/ads/fb/adset/getPlacements?device_type=:device_type&objective_type=:objective_type',
  },
}

export const adgt: IAPI = {
  endpoints: {
    fetch: '/:currentAccountID/ads/growthTool/get?id=:ad_id',
    create: '/:currentAccountID/ads/growthTool/create',
    updateTitle: '/:currentAccountID/ads/growthTool/updateTitle',
    publish: '/:currentAccountID/ads/growthTool/publish',
    saveDraft: '/:currentAccountID/ads/growthTool/saveDraft',
    preview: '/:currentAccountID/ads/growthTool/preview',
    delete: '/:currentAccountID/ads/growthTool/delete',
    discardChanges: '/:currentAccountID/ads/growthTool/discardChanges',
    duplicate: '/:currentAccountID/ads/growthTool/clone',
  },
}

export const invitation: IAPI = {
  endpoints: {
    fetch: '/auth/getInvitationData?code=:code',
    accept: '/auth/processInvitation',
  },
}

export const users: IAPI = {
  endpoints: {
    generateInviteLink: '/:currentAccountID/users/generateInviteLink',
  },
}

export const profile: IAPI = {
  endpoints: {
    checkVerification: '/profile/hasUnverifiedEmail',
    sendVerification: '/profile/sendVerificationCode',
    sendEmailVerification: '/profile/sendVerificationEmail',
    verifyEmailViaCode: '/profile/verifyEmailViaCode',
    clickConfirm: '/profile/confirmChangeEmail',
    setTimezone: '/profile/setTimezone',
    fetchMessageTagAnalytics: '/profile/getMessageTagAnalytics',
    fetchAllAccounts: '/profile/getAllAccounts',
    fetchAccount: '/profile/getAccount?account_id=:account_id',
    setDisplayFlag: '/profile/setDisplayFlag',
    setSkipPreviewOnboardingModal: '/profile/setSkipPreviewOnboardingModal',
  },
}

export const accountUser: IAPI = {
  endpoints: {
    setDisplayFlag: '/:currentAccountID/accountUser/setDisplayFlag',
  },
}

export const support: IAPI = {
  idKey: 'article_id',
  listKey: 'articles',
  endpoints: {
    createTicket: '/ticket/create',
    fetch: '/ticket/articles?section=:section',
    fetchWarnings: '/supportNotices/getVisible',
  },
}

export const devLogs: IAPI = {
  idKey: 'trace_id',
  listKey: 'list',
  endpoints: {
    fetch: '/:currentAccountID/devLog/list',
    markAsRead: ['/:currentAccountID/devLog/markAsRead', { method: 'POST' }],
    fetchPolicyEnforcementLogs: '/:currentAccountID/settings/listPolicyEnforcementLogs',
  },
}

export const agency: IAPI = {
  endpoints: {
    sendMessage: ['/agency/:agency_id/sendMessage', { method: 'POST' }],
    requestConnectPageToAgency: [
      '/:currentAccountID/settings/requestConnectPageToAgency',
      { method: 'POST' },
    ],
    clickConfirm: '/agency/confirmChangeEmail',
    getStatus: '/agency/:agencyId/getStatus',
    getAchievements: '/agency/:agencyId/getAchievements',
    getBenefitsLinks: '/agency/:agencyId/getBenefitLinks',
    sendVerification: '/agency/:agency_id/sendVerificationCode',
    getPageListWithCommission:
      '/agency/getPageListWithCommission?agency_id=:agency_id&date_start=:start_date&date_end=:end_date',
    getCommissionByPage:
      '/agency/getCommissionByPage?agency_id=:agency_id&page_id=:page_id&date_start=:start_date&date_end=:end_date',
    getMatched: '/agency/getMatched',
  },
}

export const agency_signon: IAPI = {
  endpoints: {
    fetch: '/agency/get',
    create: '/agency/createEmpty',
    update: '/agency/update',
    uploadLogo: '/agency/uploadLogo',
    sendEmail: '/agency/sendEmail',
  },
}

export const agency_listing: IAPI = {
  idKey: 'agency_id',
  listKey: 'agencies',
  endpoints: {
    fetch: '/agency/agenciesList',
    fetchInfo: '/agency/getAgency?agency_id=:agency_id',
    getFilters: '/agency/agenciesFilters',
  },
}

export const snippets = {
  fetch: '/:currentAccountID/im/liveChatSnippet/list',
  create: '/:currentAccountID/im/liveChatSnippet/create',
  delete: '/:currentAccountID/im/liveChatSnippet/delete',
  edit: '/:currentAccountID/im/liveChatSnippet/edit',
  click: '/:currentAccountID/im/liveChatSnippet/click',
  transformContent: '/:currentAccountID/im/liveChatSnippet/transformContent',
}

export const events: IAPI = {
  listKey: 'events',
  endpoints: {
    fetch: '/:currentAccountID/customEvents/list',
    create: '/:currentAccountID/customEvents/create',
    update: '/:currentAccountID/customEvents/update',
    archive: '/:currentAccountID/customEvents/archive',
    getUsesList: '/:currentAccountID/customEvents/getUsesList',
    getAvailableCurrenciesList: '/:currentAccountID/customEvents/getCurrencyList',
  },
}

export const pixel: IAPI = {
  endpoints: {
    fetchScriptUrl: '/:currentAccountID/pixelSettings/getScriptUrl',
  },
}

export const smartSegments: IAPI = {
  idKey: 'segment_id',
  itemKey: 'segment',
  listKey: 'segments',
  endpoints: {
    fetch: '/:currentAccountID/smartSegment/list',
    fetchItem: '/:currentAccountID/smartSegment/get?smart_segment_id=:id',
    createSegment: ['/:currentAccountID/smartSegment/create', { method: 'POST' }],
    deleteSegment: ['/:currentAccountID/smartSegment/delete', { method: 'POST' }],
    rename: ['/:currentAccountID/smartSegment/rename', { method: 'POST' }],
    updateDescription: ['/:currentAccountID/smartSegment/updateDescription', { method: 'POST' }],
    updateFilter: ['/:currentAccountID/smartSegment/updateFilter', { method: 'POST' }],
    getUsesList: '/:currentAccountID/smartSegment/getUsesList?smart_segment_id=:smart_segment_id',
  },
}

export const accountHealth: IAPI = {
  endpoints: {
    listSuspectedFbPolicyOffenders:
      '/:currentAccountID/accountHealth/listSuspectedFbPolicyOffenders',
  },
}

export const systemKeywords: IAPI = {
  endpoints: {
    getSubscribeFlow: '/:currentAccountID/systemKeywords/getSubscribeFlow',
    getUnsubscribeFlow: '/:currentAccountID/systemKeywords/getUnsubscribeFlow',
    getSystemFlows: '/:currentAccountID/systemKeywords/getSystemFlows',
  },
}

export const quickCampaign: IAPI = {
  idKey: 'quick_campaign_id',
  listKey: 'list',
  endpoints: {
    fetchItem: '/:currentAccountID/quickCampaign/get',
    setData: '/:currentAccountID/quickCampaign/put',
  },
  import: quickCampaignAdapter,
}

export const followUp: IAPI = {
  endpoints: {
    checkRnOptin: '/:currentAccountID/followUp/checkRnOptin',
  },
}
