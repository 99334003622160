export const RoutePaths = {
  TELEGRAM_AUTH: '/telegramAuth',
  FACEBOOK_AUTH: '/facebookAuth',

  SIGNUP: '/signup',
  SIGNIN: '/signin',

  FLOW_PLAYER_EMBED: '/flowPlayerEmbed',

  AGENCY_SIGNON: '/agency/signon',
  AGENCY_PROFILE: '/agency/:agency_id?/profile',

  HOMETAB: '/profile/dashboard',
  PROFILE_TEMPLATES: '/profile/dashboard#templates',
  PROFILE_SETTINGS: '/profile/dashboard#settings',
  PROFILE_APPLICATIONS: '/profile/dashboard#applications',
  PROFILE_FLOWS_HEALTH: '/profile/compliance-monitoring',

  REGISTRATION: '/registration/:section/:channel?/:page_id?/:type?',
  CHANNEL_CONNECTION: '/registration/channelConnection',

  DASHBOARD: '/:acc_id/dashboard',

  APPLICATIONS_CREATE: '/profile/applications/create/:page_id?',
  APPLICATION_UPDATE: '/profile/applications/:application_id/update',
  APPLICATION_INSTALLATION: '/apps/:app_hash/install',

  SETTINGS: '/:acc_id/settings',
  SETTINGS_APP_PAGE: '/:acc_id/settings/apps/:app_id',
  SETTINGS_APPS: '/:acc_id/settings#apps',

  POSTING: '/:acc_id/posting',

  SPONSORED_MESSAGES: '/:acc_id/sm',

  CHAT: '/:acc_id/chat/:thread_user_id?/:info?',

  CMS: '/:acc_id/cms',
  CMS_BUILDER: '/:acc_id/cms/files/:fso_path/:mode?/:content_id?',

  ACCOUNT_DELETED: '/account/deleted',
  SETUP_TIKTOK_INTEGRATION: '/setupTikTokIntegration',
  CONNECT_TIKTOK_INTEGRATION: '/:acc_id/setupTikTokIntegration',

  // Scope patterns
  AUDIENCE_SCOPE: '/:acc_id/subscribers/*',
  AD_TABLE_SCOPE: '/:acc_id/ads/*',
  UPGRADE_TO_PRO: '/:acc_id/upgradeToPro',
}

export const ExternalPaths = {
  HOME: '/',
  LOGOUT: '/logout',
}

export const RegistrationPaths = {
  CHANNEL_CONNECTION: '/registration/channelConnection',
  IG_CONNECTION: '/registration/channelConnection/instagram',
  WA_CONNECTION: '/registration/channelConnection/whatsapp',
  FB_CONNECTION: '/registration/channelConnection/facebook',
  TELEGRAM_CONNECTION: '/registration/channelConnection/telegram',
  EMAIL_VERIFICATION: '/registration/userSetup',
}

export const routesGroups = {
  SETTINGS: [
    '/:acc_id/settings',
    '/:acc_id/automation/menu/:menu_id?/:mode?/:flow_id?/:flow_mode?/:content_id?',
    '/:acc_id/automation/welcome/:mode?/:content_id?',
    '/:acc_id/automation/default/:mode?/:content_id?',
    '/:acc_id/automation/system-keywords/:channel/:systemKeywordType/:mode?',
    '/:acc_id/automation/storyMention/:mode?/:content_id?',
    '/:acc_id/automation/:channel?/FAQ',
    '/:acc_id/automation/:channel?/FAQ/:flow_id/:mode?/:content_id?',
  ],
  HOME: ['/:acc_id/dashboard', '/:acc_id/dashboard/flows/:flowId?/:mode?', '/:acc_id/insights'],
}

export const webViewRoutes = ['/:acc_id/webview-page/*', '/:acc_id/webview']

/**
 * Query parameter used to redirect the user back to the originally requested page
 * after authentication (login/registration).
 */
export const NEXT_PATH_SEARCH_PARAM = 'next_path'

export const RETURN_SEARCH_PARAM = 'return'
