import { createAction } from '@reduxjs/toolkit'
import { l } from '@manychat/manyui'

import { CheckoutTypes } from 'common/billing/constants/checkoutTypes'
import { CancellationReasonType } from 'common/billing/interfaces/cancellationReasons'
import {
  getActiveProProducts,
  getAddOnsData,
  getBillingProInfo,
  getBillingState,
  getHasBillingAccess,
  getProSettings,
} from 'common/billing/selectors/billingSelectors'
import { alert } from 'common/core'
import { ProductType, ProProduct } from 'common/core/interfaces/products'
import { AccountStatus } from 'common/core/models/Account/constants'
import { getCurrentAccountUser } from 'common/core/selectors/appSelectors'
import { WebSocketEvents } from 'constants/WebSockets'
import { BillingApi } from 'shared/api/requests/billing'
import { BillingProSettings, CancellationReason } from 'shared/api/requests/billing/schemas'
import { createAsyncAction } from 'shared/lib/redux'
import { accountNotificationsListener } from 'utils/services/notificationsService'

import { PRODUCT_NAME_MAP } from '../constants'
import { STRIPE_CHECKOUT_POST_UPGRADE_MESSAGES } from '../constants/Stripe'
import { billingLock } from '../helpers/billingLock'
import { UpgradeSource } from '../interfaces'
import { AddOnSlug, AddOnStatus } from '../interfaces/addOnTypes'
import { cancelAddOnModalActions } from '../redux/cancelAddOnModal'
import { disableProductModalActions } from '../redux/disableProductModal'

import { requestUpgrade } from './upgradeRequestActions'

export const cancelSubscriptionWithRefund = createAsyncAction(
  'billing/cancelSubscriptionWithRefund',
  async (_: void, { dispatch }) => {
    await BillingApi.cancelSubscriptionWithRefund()

    dispatch(
      requestUpgrade({
        source: UpgradeSource.AFTER_EXPIRED_PERIOD_NOTIFY_BAR,
      }),
    )
  },
)

interface CancelSubscriptionData
  extends Partial<Record<keyof typeof CancellationReasonType, string>> {
  ig_summer_promo_trial_disconnect?: string
}

interface CancelSubscriptionPayload {
  data: CancelSubscriptionData
  cancelImmediately?: boolean
}

interface ResponseWithPro {
  pro: BillingProSettings
}

export const cancelSubscription = createAsyncAction<ResponseWithPro, CancelSubscriptionPayload>(
  'billing/cancelSubscription',
  async (payload) => {
    const { data, cancelImmediately = false } = payload

    const response = await BillingApi.unsubscribe({
      body: {
        cancellation_data: data as Record<string, string>,
        cancel_immediately: cancelImmediately,
      },
    })

    alert(
      cancelImmediately
        ? l.translate('The trial is cancelled successfully')
        : l.translate('You have requested subscription cancellation'),
      'success',
    )

    return response.data
  },
)

interface CancellationReasonsListResponse {
  reasons: CancellationReason[]
}

export const fetchCancellationReasonsList = createAsyncAction<
  CancellationReasonsListResponse,
  void
>('billing/fetchCancellationReasonsList', async () => {
  const response = await BillingApi.fetchCancellationReasonsList()

  return response.data
})

export const backToPro = createAsyncAction<ResponseWithPro, void>(
  'billing/backToPro',
  async () => {
    const response = await BillingApi.backToPro()

    return response.data
  },
  {
    onBusinessError: (error) => {
      if (BillingApi.backToPro.isBillingCommonError(error)) {
        error.handle()
        alert(error.original_message, 'danger')
        return
      }
    },
  },
)

export const fetchPro = createAsyncAction<ResponseWithPro, boolean | void>(
  'billing/fetchPro',
  async () => {
    const response = await BillingApi.getProSettings()

    return response.data
  },
  {
    condition: (force = false, { getState }) => {
      const state = getState()
      const hasBillingAccess = getHasBillingAccess(state)
      if (!hasBillingAccess) {
        return false
      }

      const { fetchingPro } = getBillingState(state)
      const pro = getProSettings(state)

      return force || (!fetchingPro && Object.keys(pro).length === 0)
    },
  },
)

export const retryPayment = createAsyncAction(
  'billing/retryPayment',
  async () => {
    await BillingApi.retryPayment()

    alert(STRIPE_CHECKOUT_POST_UPGRADE_MESSAGES[CheckoutTypes.UPGRADE_TO_PRO], 'success')
  },
  {
    onBusinessError: (error) => {
      if (BillingApi.retryPayment.isCardError(error)) {
        error.handle()
        alert(error.original_message, 'danger')
      }
    },
  },
)

export const updateBillingEmail = createAsyncAction<void, string>(
  'billing/updateBillingEmail',
  async (email) => {
    await BillingApi.updateBillingEmail({
      body: {
        email,
      },
    })

    alert(l.translate('Successfully updated'), 'success')
  },
  {
    onBusinessError: async (error) => {
      if (BillingApi.updateBillingEmail.isInvalidEmailError(error)) {
        error.handle()
        alert(error.original_message, 'danger')
      }
    },
  },
)

export const getPrices = createAsyncAction('billing/getPrices', async () => {
  const response = await BillingApi.getPrices()

  return response.data
})

export const switchProduct = createAsyncAction(
  'billing/switchProduct',
  async (product: ProProduct, { dispatch, getState }) => {
    const state = getState()
    const { products } = getBillingProInfo(state)
    if (!products) {
      return
    }

    const newActiveStatus = !products[product].active

    await BillingApi.switchProduct({
      body: {
        product,
        isEnable: newActiveStatus,
      },
    })

    await dispatch(fetchPro(true)).unwrap()
    const successText = newActiveStatus ? l.translate('activated') : l.translate('disabled')

    alert(`${PRODUCT_NAME_MAP[product]} ${successText}`, 'success')
  },
  {
    condition: (product: ProProduct, { getState }) => {
      const state = getState()
      const { products } = getBillingProInfo(state)

      return Boolean(products && products[product])
    },
    onBusinessError: (error) => {
      if (BillingApi.switchProduct.isBillingCommonError(error)) {
        error.handle()
        alert(error.original_message, 'danger')
      }
    },
  },
)

const disableAddOnFirstAlert = (planName: string, addOnName: string) => {
  alert(
    l.translate(
      'The {planName} plan cannot be disabled while the {addOnName} add-on is turned on. If you want to disable the {planName}, it is necessary to disable the {addOnName} add-on first.',
      {
        planName,
        addOnName,
      },
    ),
    'info',
  )
}

interface TogglePlanPayload {
  type: 'plan'
  slug: ProProduct
}

interface ToggleAddOnPayload {
  type: 'addOn'
  slug: AddOnSlug
}

type ToggleProductPayload = TogglePlanPayload | ToggleAddOnPayload

export const toggleProduct = createAsyncAction<void, ToggleProductPayload>(
  'billing/toggleProduct',
  async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi
    const state = getState()
    const activeProducts = getActiveProProducts(state)
    const addOns = getAddOnsData(state)

    if (payload.type === 'addOn') {
      const addOn = addOns.find((addOn) => addOn.slug === payload.slug)
      if (!addOn) {
        return
      }

      if (addOn.status === AddOnStatus.ACTIVE) {
        dispatch(cancelAddOnModalActions.open(payload.slug))
      } else {
        dispatch(
          requestUpgrade({
            source: UpgradeSource.BILLING_ENABLE_SECOND_PRODUCT,

            addOns: [payload.slug],
          }),
        )
      }
      return
    }

    const plan = payload.slug
    if (activeProducts.includes(plan)) {
      const activeAddOn = addOns.find((addOn) => addOn.status === AddOnStatus.ACTIVE)

      // prevents disabling Marketing Automation with active add-on
      if (plan === ProductType.AUTOMATION && activeAddOn) {
        return disableAddOnFirstAlert(PRODUCT_NAME_MAP[ProductType.AUTOMATION], activeAddOn.name)
      }

      dispatch(disableProductModalActions.open(plan))
    } else {
      dispatch(
        requestUpgrade({
          source: UpgradeSource.BILLING_ENABLE_SECOND_PRODUCT,
          products: [plan],
        }),
      )
    }
  },
  {
    condition: (_, thunkApi) => {
      const state = thunkApi.getState()
      const activeProducts = getActiveProProducts(state)

      return Boolean(activeProducts.length)
    },
  },
)

export const proStatusUpdate = createAction<AccountStatus>('billing/proStatusUpdate')

accountNotificationsListener.on(
  WebSocketEvents.SUBSCRIPTION_UPDATED,
  async (data, dispatch, getState) => {
    const state = getState()
    const hasBillingAccess = getCurrentAccountUser(state as RootState).has_billing_access || false
    const release = await billingLock.acquire()

    if (hasBillingAccess) {
      await dispatch(fetchPro(true))
    } else {
      dispatch(proStatusUpdate(data.model.pro_status))
    }

    release()
  },
)
