import { z } from 'zod'

import { PathField } from '../pathFields'
import { EasyBuilderConfigFormSectionType } from '../viewSectionTypes'

export enum FormCommentVariant {
  REPLY = 'reply',
  COMMENT = 'comment',
  DIRECT_MESSAGE = 'directMessage',
}

const viewSectionFormCommentSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.KEYWORDS),
  variant: z.literal(FormCommentVariant.COMMENT).optional(),
  path: z.object({
    keywordCondition: z.literal(PathField.COMMENT_CONTAINS),
    keywords: z.literal(PathField.INCLUDE_KEYWORDS),
  }),
})

const viewSectionFormReplySchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.KEYWORDS),
  variant: z.literal(FormCommentVariant.REPLY),
  path: z.object({
    keywordCondition: z.literal(PathField.KEYWORDS_CONDITION),
    keywords: z.literal(PathField.KEYWORDS),
  }),
})

const viewSectionFormDirectMessageSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.KEYWORDS),
  variant: z.literal(FormCommentVariant.DIRECT_MESSAGE),
  path: z.object({
    keywords: z.literal(PathField.KEYWORD_RULES),
  }),
})

export const viewSectionFormKeywordsSchema = z.union([
  viewSectionFormReplySchema,
  viewSectionFormCommentSchema,
  viewSectionFormDirectMessageSchema,
])
