import type { PostCoveredArea, StoryCoveredArea } from 'apps/growthTools/models/Widget/constants'
import type {
  CommentContainsValues,
  KeywordsConditionValues,
} from 'common/builder/models/constants'

export enum PathField {
  COMMENT_CONTAINS = 'comment_contains',
  INCLUDE_KEYWORDS = 'include_keywords',
  DM_MESSAGE_TEXT = 'dm_link_message_text',
  DM_LINK = 'dm_link_button_link',
  DM_BUTTON = 'dm_link_button_caption',
  POST_COVERED_AREA = 'post_covered_area',
  POST_ID = 'post_id',
  WELCOME_MESSAGE_TEXT = 'dm_welcome_message_text',
  WELCOME_MESSAGE_LABEL = 'dm_welcome_button_caption',
  PUBLIC_REPLY_ENABLED = 'use_case_publicly_reply_to_comment',
  PUBLIC_REPLY_MESSAGES = 'public_reply_messages',
  COLLECT_EMAILS_ENABLED = 'use_case_collect_email',
  COLLECT_EMAILS_MESSAGE = 'question_collect_email',
  REQUEST_FOLLOW_ENABLED = 'use_case_ask_for_follow',
  AUTOMATICALLY_LIKE_REPLIES = 'use_case_automatically_like_replies',
  REQUEST_FOLLOW_MESSAGE = 'dm_follow_before_sending_link_text',
  FOLLOW_UP_ENABLED = 'use_case_follow_up',
  WELCOME_MESSAGE_ENABLED = 'use_case_welcome_message',
  CGT_FOLLOW_UP_MESSAGE = 'dm_reminder_text',
  DM_NOT_FOLLOWED_TEXT = 'dm_not_followed_text',
  DM_REMINDER_LINK_BUTTON_LINK = 'dm_reminder_link_button_link',
  DM_REMINDER_LINK_BUTTON_CAPTION = 'dm_reminder_link_button_caption',
  STORY_SELECT = 'story_select',
  STORY_ID = 'story_id',
  KEYWORDS_CONDITION = 'keywords_condition',
  KEYWORDS = 'keywords',
  AUTO_LIKE = 'auto_like',
  DM_FOLLOW_UP_MESSAGE = 'dm_follow_up',
  KEYWORD_RULES = 'keyword_rules',
  DM_LINK_BUTTON_1 = 'dm_link_button_1',
  DM_LINK_BUTTON_2 = 'dm_link_button_2',
  DM_LINK_BUTTON_3 = 'dm_link_button_3',
}

export interface PathFieldTypes {
  [PathField.COMMENT_CONTAINS]: CommentContainsValues
  [PathField.INCLUDE_KEYWORDS]: string[]
  [PathField.DM_MESSAGE_TEXT]: string
  [PathField.DM_LINK]: string
  [PathField.DM_BUTTON]: string
  [PathField.POST_COVERED_AREA]: PostCoveredArea
  [PathField.POST_ID]: string
  [PathField.STORY_SELECT]: StoryCoveredArea
  [PathField.STORY_ID]: string | null
  [PathField.KEYWORDS_CONDITION]: KeywordsConditionValues
  [PathField.KEYWORDS]: string[]
  [PathField.AUTO_LIKE]: boolean
  [PathField.AUTOMATICALLY_LIKE_REPLIES]: boolean
  [PathField.WELCOME_MESSAGE_TEXT]: string
  [PathField.WELCOME_MESSAGE_LABEL]: string
  [PathField.PUBLIC_REPLY_ENABLED]: boolean
  [PathField.PUBLIC_REPLY_MESSAGES]: string[]
  [PathField.COLLECT_EMAILS_ENABLED]: boolean
  [PathField.COLLECT_EMAILS_MESSAGE]: string
  [PathField.REQUEST_FOLLOW_ENABLED]: boolean
  [PathField.REQUEST_FOLLOW_MESSAGE]: string
  [PathField.FOLLOW_UP_ENABLED]: boolean
  [PathField.CGT_FOLLOW_UP_MESSAGE]: string
  [PathField.DM_FOLLOW_UP_MESSAGE]: string
  [PathField.DM_NOT_FOLLOWED_TEXT]: never
  [PathField.DM_REMINDER_LINK_BUTTON_LINK]: never
  [PathField.DM_REMINDER_LINK_BUTTON_CAPTION]: never
  [PathField.WELCOME_MESSAGE_ENABLED]: boolean
  [PathField.KEYWORD_RULES]: Array<{
    keywords: string[]
    condition: KeywordsConditionValues
  }>
  [PathField.DM_LINK_BUTTON_1]: EasyBuilderDmFormLinkButton
  [PathField.DM_LINK_BUTTON_2]: EasyBuilderDmFormLinkButton
  [PathField.DM_LINK_BUTTON_3]: EasyBuilderDmFormLinkButton
}

export interface EasyBuilderDmFormLinkButton {
  url: string
  caption: string
  is_active: boolean
}
