import { z } from 'zod'

import { PathField } from '../pathFields'
import { EasyBuilderConfigFormSectionType } from '../viewSectionTypes'

export enum FormDirectResponseVariant {
  ONE_LINK = 'oneLink',
  MULTI_LINKS = 'multiLinks',
}

const viewSectionFormDirectResponseOneLinkSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.DIRECT_RESPONSE),
  variant: z.literal(FormDirectResponseVariant.ONE_LINK),
  path: z.object({
    text: z.literal(PathField.DM_MESSAGE_TEXT),
    linkCaption: z.literal(PathField.DM_BUTTON),
    linkUrl: z.literal(PathField.DM_LINK),
  }),
})

const viewSectionFormDirectResponseMultiLinksSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.DIRECT_RESPONSE),
  variant: z.literal(FormDirectResponseVariant.MULTI_LINKS).optional(),
  path: z.object({
    text: z.literal(PathField.DM_MESSAGE_TEXT),
    link1: z.literal(PathField.DM_LINK_BUTTON_1),
    link2: z.literal(PathField.DM_LINK_BUTTON_2),
    link3: z.literal(PathField.DM_LINK_BUTTON_3),
  }),
})

export const viewSectionFormDirectResponseSchema = z.union([
  viewSectionFormDirectResponseOneLinkSchema,
  viewSectionFormDirectResponseMultiLinksSchema,
])
