import { z } from 'zod'

import { PathField } from '../pathFields'
import { EasyBuilderConfigFormSectionType } from '../viewSectionTypes'

export enum FormSelectMediaVariant {
  POST = 'post',
  POST_WITH_NEXT = 'post_with_next',
  STORY = 'story',
}

const viewSectionFormSelectPostSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.SELECT_MEDIA),
  variant: z
    .union([
      z.literal(FormSelectMediaVariant.POST),
      z.literal(FormSelectMediaVariant.POST_WITH_NEXT),
    ])
    .optional(),
  path: z.object({
    mediaSelect: z.literal(PathField.POST_COVERED_AREA),
    mediaId: z.literal(PathField.POST_ID),
  }),
})

const viewSectionFormSelectStorySchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.SELECT_MEDIA),
  variant: z.literal(FormSelectMediaVariant.STORY),
  path: z.object({
    mediaSelect: z.literal(PathField.STORY_SELECT),
    mediaId: z.literal(PathField.STORY_ID),
  }),
})

export const viewSectionFormSelectMediaSchema = z.union([
  viewSectionFormSelectPostSchema,
  viewSectionFormSelectStorySchema,
])
