import { useAppSelector } from 'reduxTyped'
import { Icon, IconComponent, l } from '@manychat/manyui'

import { ChannelType, ChannelTypeLabel } from 'common/core/constants/ChannelType'
import { IFolder } from 'common/core/interfaces/folder'
import { getTooltipTexts } from 'common/fields/constants/TooltipTexts'
import { FieldGroupId, FieldType } from 'common/fields/entities/enums'
import { Field } from 'common/fields/entities/field'
import {
  ContextFields,
  CustomBotFields,
  CustomUserFields,
  FieldGroup,
  IntegrationFields,
  StaticFields,
  SystemFields,
  FormatDateFields,
  TagFields,
  RecommendedFields,
} from 'common/fields/entities/fieldGroups'
import withProBadge from 'common/fields/linking/hooks/withProBadge'
import { getGlobalFieldFolders } from 'common/fields/selectors/globalFieldFoldersSelectors'
import { getUserFieldFolders } from 'common/fields/selectors/userFieldFoldersSelectors'
import { FilterField } from 'common/filter/models/AudienceFilter/constants'
import { getSortedTagFolders } from 'common/tags/selectors/tagFoldersSelectors'
import { IconData, IOption } from 'components/AutocompleteElements/OptionsList/types'

export interface IFieldOption extends IOption {
  field_id?: number
  parent?: Omit<FieldOptionGroup, 'value'>
  group?: FieldGroupId
}

export interface FieldOptionGroup {
  label: string
  group: FieldGroupId
  options: IFieldOption[]
  icon?: IconData
}

export const useFieldsOptions = (fields: Field[]): FieldOptionGroup[] => {
  const userFieldFolders = useAppSelector(getUserFieldFolders)
  const globalFieldFolders = useAppSelector(getGlobalFieldFolders)
  const tagFolders = useAppSelector(getSortedTagFolders)
  const getFieldGroupOption = makeGetFieldGroupOption(fields)

  if (fields.some((field) => field.group === FormatDateFields.id)) {
    return [getFieldGroupOption(FormatDateFields, (field) => field.part || null)]
  }

  const getFieldFolderTitle = (field: Field, folders: IFolder[]) =>
    folders.find((folder) => folder.id === field.folderId)?.title ?? null
  const getUserFieldFolderTitle = (field: Field) => getFieldFolderTitle(field, userFieldFolders)
  const getGlobalFieldFolderTitle = (field: Field) => getFieldFolderTitle(field, globalFieldFolders)
  const getTagFolderTitle = (field: Field) => getFieldFolderTitle(field, tagFolders)
  const getFieldChannel = (field: Field) => {
    return field.channel ? ChannelTypeLabel[field.channel] : null
  }

  const recommendedFieldGroupOptions = getFieldGroupOption(RecommendedFields)
  recommendedFieldGroupOptions.options.forEach((option) => {
    option.value = option.value?.replace('_recommended', '')
  })

  const fieldGroups = [
    recommendedFieldGroupOptions,
    getFieldGroupOption(StaticFields),
    getFieldGroupOption(SystemFields, getFieldChannel, getFieldChannelIcon),
    getFieldGroupOption(CustomUserFields, getUserFieldFolderTitle, getFolderIcon),
    getFieldGroupOption(CustomBotFields, getGlobalFieldFolderTitle, getFolderIcon),
    getFieldGroupOption(TagFields, getTagFolderTitle, getFolderIcon),
    getFieldGroupOption(IntegrationFields, getIntegrationName, getIntegrationIcon),
    getFieldGroupOption(ContextFields, getContextName),
  ]

  return fieldGroups
}

type SubGroupNameResolver = (field: Field) => string | null
type SubGroupIconResolver = (option: IFieldOption) => IconData | null

const makeGetFieldGroupOption =
  (fields: Field[]) =>
  (
    group: FieldGroup,
    getSubGroupName: SubGroupNameResolver = () => null,
    getSubGroupIcon: SubGroupIconResolver = () => null,
  ) => {
    const groupFields = fields.filter((field) => field.group === group.id)
    const result: IFieldOption[] = createOptions(groupFields, (field) => !getSubGroupName(field))

    const subGroupNames = getSubGroupNames(fields, getSubGroupName)
    for (const subGroupName of subGroupNames) {
      const subGroup = createOptionsGroup(
        subGroupName,
        group.id,
        createOptions(groupFields, (field) => getSubGroupName(field) === subGroupName),
      )
      const icon = getSubGroupIcon(subGroup)
      if (icon) {
        subGroup.icon = icon
      }
      subGroup.options.length > 0 && result.push(subGroup)
    }

    const optionsGroup = createOptionsGroup(l.getString(group.caption), group.id, result)

    return optionsGroup
  }

const getSubGroupNames = (fields: Field[], getSubGroupName: SubGroupNameResolver) => {
  const result: string[] = []
  for (const field of fields) {
    const subGroupName = getSubGroupName(field)
    if (!subGroupName || result.includes(subGroupName)) {
      continue
    }
    result.push(subGroupName)
  }
  return result
}

const createOptions = (fields: Field[], filter: (field: Field) => boolean) => {
  return fields.filter(filter).map(mapFieldToOption)
}

const createOptionsGroup = (
  label: string,
  group: FieldGroupId,
  options: IFieldOption[],
): FieldOptionGroup => ({
  label,
  group,
  options,
})

const mapFieldToOption = (field: Field): IFieldOption => {
  const result: IFieldOption = {
    value: field.id,
    label: field.caption,
    icon: getFieldIcon(field),
    tooltip: getTooltipText(field.id),
    description: field.description,
  }
  if (field.id === 'smart_segment') {
    result.label = withProBadge(result.label)
  }
  return result
}

const getIntegrationName = (field: Field) => {
  return field.integrationId || 'Unknown Integration'
}

const getIntegrationIcon = (): null => {
  return null
}

const getContextName = (field: Field) => field.contextName || null

const getFolderIcon = (): IconData => ({ component: Icon.Folder, color: '#0084FF', size: 16 })

export const FieldTypeIcons: Record<string, IconComponent> = {
  array: Icon.CufArray,
  boolean: Icon.CufBoolean,
  date: Icon.Calendar,
  datetime: Icon.Time,
  enum: Icon.CufEnum,
  number: Icon.CufNumber,
  tag: Icon.AddTag,
  text: Icon.CufText,
  phone: Icon.PhoneOutgoing,
  email: Icon.SponsoredMessages,
}

const StaticFieldIcons: Partial<Record<FilterField, IconComponent>> = {
  [FilterField.SMART_SEGMENT]: Icon.OrphanAdStatistics,
  [FilterField.TAG]: Icon.AddTag,
  [FilterField.WIDGET]: Icon.GrowthTools,
  [FilterField.OPTIN_THROUGH_ADS]: Icon.Ads,
  [FilterField.SEQUENCE]: Icon.Sequences,
  [FilterField.CURRENT_DATETIME]: Icon.Time,
  [FilterField.ONE_TIME_NOTIFICATION]: Icon.FacebookMessenger,
  [FilterField.ONE_TIME_NOTIFICATION_OPTIN]: Icon.Ads,
  [FilterField.IG_RECURRING_NOTIFICATION]: Icon.InstagramChannel3,
  [FilterField.IG_RECURRING_NOTIFICATION_OPTIN]: Icon.InstagramChannel3,
  [FilterField.OPT_IN_THROUGH_API]: Icon.CloudUpload,
  [FilterField.OPT_IN_THROUGH_TIKTOK_ADS]: Icon.TikTokChannel2,
}

const getFieldIconColor = (field: Field) => {
  if (field.type === FieldType.TAG) return '#FF9900'
  return '#5A677D'
}

const getFieldIcon = (field: Field) => {
  let component: IconComponent
  const color = getFieldIconColor(field)
  if (field.group === FieldGroupId.STATIC) {
    component = StaticFieldIcons[field.id as FilterField] || Icon.AddTag
  } else if (field.id === FilterField.PHONE_US_STATE) {
    component = Icon.Nationality
  } else if (field.id === FilterField.PHONE) {
    component = Icon.PhoneOutgoing
  } else if (field.id === FilterField.EMAIL) {
    component = Icon.SponsoredMessages
  } else if (field.id === FilterField.CHANNEL) {
    component = Icon.OmnichannelWidget
  } else if (field.id === FilterField.LAST_REPLY_TYPE) {
    component = Icon.SendMessage
  } else {
    const iconType = field.type.split('_').pop() || ''
    component = FieldTypeIcons[iconType] || Icon.CufNoIcon
  }

  return component ? { size: 16, color, component } : undefined
}

const getTooltipText = (id: string): string | undefined => getTooltipTexts(id)

const FieldChannel: Record<string, IconComponent> = {
  [ChannelTypeLabel[ChannelType.INSTAGRAM]]: Icon.InstagramChannel,
  [ChannelTypeLabel[ChannelType.TELEGRAM]]: Icon.TelegramChannel,
  [ChannelTypeLabel[ChannelType.FB]]: Icon.MessengerChannel,
  [ChannelTypeLabel[ChannelType.SMS]]: Icon.SmsChannel,
  [ChannelTypeLabel[ChannelType.EMAIL]]: Icon.EmailChannel,
  [ChannelTypeLabel[ChannelType.WHATSAPP]]: Icon.WhatsAppChannel,
  [ChannelTypeLabel[ChannelType.TIKTOK]]: Icon.TikTokChannel,
}

const getFieldChannelIcon = (option: IFieldOption): IconData | null => {
  if (!option.label || typeof option.label !== 'string') return null

  return { component: FieldChannel[option.label], size: 16, color: '#5A677D' }
}
