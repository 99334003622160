export enum EasyBuilderConfigNodeType {
  TITLE_NODE = 'titleNode',
  FORM_SECTION_NODE = 'formSectionNode',
}

export enum EasyBuilderConfigFormSectionType {
  SELECT_MEDIA = 'selectMedia',
  KEYWORDS = 'keywords',
  OPENING_MESSAGE = 'openingMessage',
  DIRECT_RESPONSE = 'directResponse',
  ADDITION_CASE = 'additionCase',
}
