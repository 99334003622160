import { WidgetTypes } from 'apps/growthTools/models/Widget/constants'
import { ProActionTypes } from 'common/actions'
import { CheckoutSources } from 'common/billing/constants/checkoutTypes'
import { ProBlockTypes } from 'common/builder/models/Block/constants'
import { ChannelType, ConnectChannelModalSource } from 'common/core/constants/ChannelType'

export enum UpgradeSource {
  ADS_CREATE_NEW = 'ADS.CREATE_NEW',
  AFTER_EXPIRED_BANNER = 'AFTER_EXPIRED_BANNER',
  AFTER_EXPIRED_PERIOD_NOTIFY_BAR = 'AFTER_EXPIRED_PERIOD_NOTIFY_BAR',
  ALL_POSTS = 'all_posts',
  IG_CGT_ALL_POSTS = 'ig_cgt_all_posts',
  NEXT_POST_OR_REEL = 'ig_cgt_next_post_or_reel',
  AUDIENCE_EXPORT = 'audience_export',
  AUDIENCE_MERGE = 'audience_merge',
  BILLING_ENABLE_SECOND_PRODUCT = 'BILLING_ENABLE_SECOND_PRODUCT',
  BILLING_INFO_UPGRADE_CLICKED = 'BILLING_INFO.UPGRADE.CLICKED',
  BILLING_INFO_UPGRADE_ON_MOUNT = 'BILLING_INFO.UPGRADE.ON_MOUNT',
  BILLING_INFO_ADD_ONS = 'BILLING_INFO.ADD_ONS.CLICK',
  BLOCKED_PRO_MODAL = 'BLOCKED_PRO_MODAL',
  BROADCASTS_SEND_NOW = 'BROADCASTS.SEND_NOW',
  BROADCAST_CREATE = 'BROADCAST_CREATE',
  BUILDER_BUY_BUTTON = 'builder_buy_button',
  BUILDER_NODES = 'builder_nodes',
  BUILDER_PRO_CONTENT_TYPE = 'builder_pro_content_type',
  BUILDER_TRIGGER_SIDEBAR = 'builderTriggerSidebar',
  CONNECT_SMS_CHANNEL = 'CONNECT_SMS_CHANNEL',
  CONTACTS_LIMIT_ALERT = 'CONTACTS_LIMIT_ALERT',
  CREATE_BROADCAST_FROM_FLOW = 'Create broadcast from flow',
  DEFAULT_REPLY_BUILDER = 'default_reply_builder',
  DISABLE_BRANDING = 'disable_branding',
  WIDGETS_TRIGGER_LIMIT = 'widgets_trigger_limit',
  STORY_REPLY_TRIGGERS_LIMIT = 'story_reply_triggers_limit',
  TRIGGER_STORY_REPLY_AUTO_LIKE = 'trigger_story_reply_auto_like',
  TRIGGER_STORY_REPLY_DELAY_ANSWER = 'trigger_story_reply_delay_answer',
  NODE_SMART_DELAY = 'node_smart_delay',
  DUPLICATE_BROADCAST = 'Duplicate broadcast',
  DYNAMIC_MAIN_MENU = 'Dynamic Main Menu',
  EMAIL = 'email',
  EMAIL_ACTIVATE = 'EMAIL_ACTIVATE',
  ENABLE_SMS_CHANNEL = 'ENABLE_SMS_CHANNEL',
  EXPIRED_BANNER = 'EXPIRED_BANNER',
  EXPIRED_NOTIFY_BAR = 'EXPIRED_NOTIFY_BAR',
  GET_API_KEY = 'get_api_key',
  GROWTH_TOOLS = 'growth_tools',
  GROWTH_TOOLS_ADS_JSON = 'ads_json',
  GROWTH_TOOLS_BAR = 'bar',
  GROWTH_TOOLS_BOX = 'box',
  GROWTH_TOOLS_BUTTON = 'button',
  GROWTH_TOOLS_CHECKBOX = 'checkbox',
  GROWTH_TOOLS_CUSTOMER_CHAT = 'customer_chat',
  GROWTH_TOOLS_FACEBOOK_SHOPS = 'facebook_shops',
  GROWTH_TOOLS_FEED_COMMENT = 'feed_comment',
  GROWTH_TOOLS_FEED_COMMENT_TRIGGER = 'feed_comment_trigger',
  GROWTH_TOOLS_LANDING = 'landing',
  GROWTH_TOOLS_MESSENGER_CODE = 'messenger_code',
  GROWTH_TOOLS_MESSENGER_REF_URL = 'messenger_ref_url',
  GROWTH_TOOLS_MODAL = 'modal',
  GROWTH_TOOLS_OMNICHAT = 'omnichat',
  GROWTH_TOOLS_PAGE_TAKEOVER = 'page_takeover',
  GROWTH_TOOLS_SLIDE_IN = 'slide_in',
  GROWTH_TOOL_ENABLE = 'GROWTH_TOOL_ENABLE',
  HOME_TAB = 'home_tab',
  IMPORT_CONTACT = 'IMPORT_CONTACT',
  INSTALL_TEMPLATE = 'INSTALL_TEMPLATE',
  INTEGRATION_CONNECT = 'INTEGRATION.CONNECT',
  INTEGROMAT = 'Make',
  KEYWORD_LIMIT = 'keyword_limit',
  LIMIT_BLOCKED_BANNER = 'LIMIT_BLOCKED_BANNER',
  LIVECHAT_FACEBOOK = 'LiveChat facebook',
  LIVECHAT_INSTAGRAM = 'LiveChat Instagram',
  LIVECHAT_TELEGRAM = 'LiveChat Telegram',
  LIVECHAT_TIKTOK = 'LiveChat Tiktok',
  MAIN_MENU = 'main_menu',
  MEMBER_LIMIT = 'member-limit',
  MESSAGE_TAGS_MIGRATION = 'MESSAGE_TAGS_MIGRATION',
  NEW_BROADCAST = 'New broadcast',
  NONE = '',
  PAID_MESSAGES_CREATE_NEW = 'PAID_MESSAGES.CREATE_NEW',
  PAY_PAL_CONNECT = 'PAY_PAL_CONNECT',
  RENEW = 'Renew',
  RULES = 'rules',
  RULE_DETAIL = 'ruleDetail',
  SAVE_WIDGET_VALIDATION = 'SAVE_WIDGET_VALIDATION',
  SEQUENCES = 'sequences',
  SETTINGS = 'settings',
  SELF_SERVE_INSTALL = 'SELF_SERVE_INSTALL',
  SETTINGS_AUTO_ASSIGNMENT_PAGE = 'SETTINGS.AUTO_ASSIGNMENT_PAGE',
  SETTINGS_TEAM = 'Settings Team',
  SHORTENER = 'shortener',
  SHOW_ALL_PRODUCT_FEATURES = 'SHOW_ALL_PRODUCT_FEATURES',
  SIDEBAR = 'sidebar',
  SMART_SEGMENTS = 'smart_segments',
  SMS = 'sms',
  STRIPE_CONNECT = 'STRIPE_CONNECT',
  SUBSCRIBER_LIMIT_BANNER = 'SUBSCRIBER_LIMIT_BANNER',
  TAGS = 'tags',
  TEST = 'test',
  TRIGGER_ZAP_ACTION = 'trigger_zap_action',
  USER_FIELDS = 'user_fields',
  WA_FREE_PERIOD_END = 'WA_FREE_PERIOD_END',
  WA_UPGRADE_TO_PRO = 'WA_UPGRADE_TO_PRO',
  WIDGETS_LIMIT = 'widgets-limit',
  ZAPIER = 'zapier',
  FLOW_WIDGET_TOGGLE = 'flow_widget_toggle',
  NOTIFICATION_BAR_PRO_TRIAL_EXPERIMENT = 'notification_bar_pro_trial_experiment',
  MODAL_FIRST_AUTOMATION_LIVE = 'modal_first_automation_live',
  NOTIFICATION_BAR_VERIFY_PAYMENT = 'notification_bar_verify_payment',
  URL_BUTTON_POWERED_BY_MC = 'url_button_powered_by_mc',
  UPGRADE_SIMPLE_TRIAL = 'UPGRADE_SIMPLE_TRIAL',
  WA_CREDIT_LINE_ISSUE_TOOLTIP = 'WA_CREDIT_LINE_ISSUE_TOOLTIP',

  // Easy Builder Source
  EB_FOLLOW_UP = 'EB_FOLLOW_UP',
  EB_ASK_FOR_FOLLOW = 'EB_ASK_FOR_FOLLOW',
  EB_ASK_FOR_MAILS_IN_DM = 'EB_ASK_FOR_MAILS_IN_DM',
  EB_AUTOMATICALLY_LIKE_REPLIES = 'EB_AUTOMATICALLY_LIKE_REPLIES',
  EB_IG_CGT_ALL_POSTS = 'EB_IG_CGT_ALL_POSTS',
  EB_NEXT_POST_OR_REEL = 'EB_NEXT_POST_OR_REEL',

  // AI Features Source
  AI_INTENT_TOGGLE = 'ai_intent_toggle',
  AI_TEXT_IMPROVER = 'AI_TEXT_IMPROVER',
  INTENTS_BUILDER = 'INTENTS_BUILDER',
  AI_PLAYGROUND_LIMIT_ERROR = 'AI_PLAYGROUND_LIMIT_ERROR',
  FLOW_PUBLISH_AI_ADDON_REQUIRED = 'flow_publish_ai_addon_required',
  AI_EDUCATIONAL_MODAL = 'ai_educational_modal',
  AI_PROMOTIONS_TEMPLATE = 'ai_promotions_template',
  AI_BULK_FAQ = 'ai_bulk_faq',

  IN_APP_CARD_ATTACHMENT_EXPERIMENT = 'IN_APP_CARD_ATTACHMENT_EXPERIMENT',
  BILLING_ADDRESS_ONLY_US_EXPERIMENT = 'BILLING_ADDRESS_ONLY_US_EXPERIMENT',
}

const broadcastSources = Object.values(ChannelType).map(
  (type) => `BROADCAST.CONNECT ${type}` as const,
)
type BroadcastUpgradeSource = typeof broadcastSources[number]

const widgetSources = Object.values(WidgetTypes).map((type) => `growth_tools_${type}` as const)
type WidgetUpgradeSource = typeof widgetSources[number]

const builderBlockSources = ProBlockTypes.map((type) => `builder_blocks_${type}` as const)
type BuilderBlockUpgradeSource = typeof builderBlockSources[number]

const builderActionSources = ProActionTypes.map((type) => `builder_actions_${type}` as const)
type BuilderActionUpgradeSource = typeof builderActionSources[number]

type ConnectChannelUpgradeSource = `${ConnectChannelModalSource}.CONNECT ${ChannelType}`

export type UpgradeSourceType =
  | UpgradeSource
  | BroadcastUpgradeSource
  | WidgetUpgradeSource
  | BuilderBlockUpgradeSource
  | BuilderActionUpgradeSource
  | ConnectChannelUpgradeSource
  | CheckoutSources

/*
 * Guards
 */
const isBroadcastUpgradeSource = (source: unknown): source is BroadcastUpgradeSource => {
  return broadcastSources.includes(source as BroadcastUpgradeSource)
}

const isWidgetUpgradeSource = (source: unknown): source is WidgetUpgradeSource => {
  return widgetSources.includes(source as WidgetUpgradeSource)
}

const isBuilderBlockUpgradeSource = (source: unknown): source is BuilderBlockUpgradeSource => {
  return builderBlockSources.includes(source as BuilderBlockUpgradeSource)
}

const isBuilderActionUpgradeSource = (source: unknown): source is BuilderActionUpgradeSource => {
  return builderActionSources.includes(source as BuilderActionUpgradeSource)
}

const isConnectChannelUpgradeSource = (source: unknown): source is ConnectChannelUpgradeSource => {
  const connectSources = Object.values(ConnectChannelModalSource)
  const connectTypes = Object.values(ChannelType)

  const sources: string[] = []
  for (const connectSource of connectSources) {
    for (const connectType of connectTypes) {
      sources.push(`${connectSource}.CONNECT ${connectType}`)
    }
  }

  return sources.includes(source as string)
}

const isEnumUpgradeSource = (source: unknown): source is UpgradeSource => {
  return Object.values(UpgradeSource).includes(source as UpgradeSource)
}

export const isUpgradeSource = (source: unknown): source is UpgradeSource =>
  [
    isBroadcastUpgradeSource,
    isWidgetUpgradeSource,
    isBuilderBlockUpgradeSource,
    isBuilderActionUpgradeSource,
    isConnectChannelUpgradeSource,
    isEnumUpgradeSource,
  ].some((guard) => guard(source))
