import { api } from 'shared/api'

import { questionnaireApiSchemas } from './schemas'

export const questionnaireApi = {
  getPageQuestions: api.account.createGet({
    url: '/questionnaireAccount/getQuestions',
    schemas: questionnaireApiSchemas.getPageQuestions,
  }),
  savePageAnswers: api.account.createPost({
    url: '/questionnaireAccount/save',
    schemas: questionnaireApiSchemas.savePageAnswers,
  }),
  saveUserAnswers: api.base.createPost({
    url: '/questionnaireUser/save',
    schemas: questionnaireApiSchemas.saveUserAnswers,
  }),
}
