export enum EasyBuilderConfigStringTitleSet {
  WHEN_SOMEONE_COMMENTS_ON = 'whenSomeoneCommentsOn',
  AND_THIS_COMMENT_HAS = 'andThisCommentHas',
  THEY_WILL_GET = 'theyWillGet',
  OTHER_THINGS_TO_AUTOMATE = 'otherThingsToAutomate',
  FIRST_THEY_GET_OPENING_DM = 'fitstTheyGetOpeningDm',
  THEN_THEY_GET_DM_WITH_LINK = 'thenTheyGetDmWithLink',
  WHEN_SOMEONE_DMS_YOU = 'whenSomeoneDmsYou',
  THEY_GET_DM_BACK = 'theyGetDmBack',
  WHEN_SOMEONE_REPLY_TO = 'whenSomeoneReplyTo',
  AND_REPLY_HAS = 'andReplyHas',
}

export enum EasyBuilderConfigStringSectionSet {
  FOLLOW_UP_CGT = 'followUpCgt',
  FOLLOW_UP_SR = 'followUpSr',
  REQUEST_FOLLOW_CGT = 'requestFollowCgt',
  REQUEST_FOLLOW_SR = 'requestFollowSr',
  REQUEST_FOLLOW_PRESELECTED_CGT = 'requestFollowPreselectedCgt',
  COLLECT_EMAILS_CGT = 'collectEmailsCgt',
  COLLECT_EMAILS_SR = 'collectEmailsSr',
}
