import { z } from 'zod'

import { PathField } from '../pathFields'
import { EasyBuilderConfigFormSectionType } from '../viewSectionTypes'

export const viewSectionFormOpeningMessageSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.OPENING_MESSAGE),
  toggleHidden: z.boolean().optional(),
  path: z.object({
    text: z.literal(PathField.WELCOME_MESSAGE_TEXT),
    button: z.literal(PathField.WELCOME_MESSAGE_LABEL),
    enabled: z.literal(PathField.WELCOME_MESSAGE_ENABLED).optional(),
  }),
})
