import { createAction } from '@reduxjs/toolkit'
import { Stripe } from '@stripe/stripe-js'
import { l } from '@manychat/manyui'

import { alert } from 'common/core'
import { ProductType } from 'common/core/interfaces/products'
import { getCurrentAccountProductList, isTrialAvailable } from 'common/core/selectors/appSelectors'
import { CancelError, isCancelError } from 'shared/api/lib/errors/business/local'
import { StripeError } from 'shared/api/lib/errors/business/stripeError'
import { BillingApi } from 'shared/api/requests/billing'
import { StripeCheckoutInfo } from 'shared/api/requests/billing/schemas'
import { billingFlexApi } from 'shared/api/requests/billingFlex'
import { BillingFlexApiGuards } from 'shared/api/requests/billingFlex/guards'
import {
  CheckoutAndConnectCardRequest,
  StripeIntentType,
  StripeCreateCustomerPayload,
  StripeConfirmedIntentSchema,
} from 'shared/api/requests/billingFlex/schemas'
import { createAsyncAction } from 'shared/lib/redux'
import { getQuery, getPathname, getHash, getSearch } from 'utils/router/tools'
import errorTrackingService from 'utils/services/errorTrackingService'

import { BillingVersion } from '../constants/AccountBillingInfo'
import { CheckoutSources, CheckoutTypes } from '../constants/checkoutTypes'
import { getStripeCheckoutType } from '../helpers/getStripeCheckoutType'
import { sendStripeMetric, StripeMetricsContext } from '../helpers/sendStripeMetric'
import { UpgradeSourceType } from '../interfaces'
import { AddOnSlug } from '../interfaces/addOnTypes'
import { getBillingProInfo, getUpgradeRequest } from '../selectors/billingSelectors'
import { getBillingVersion } from '../selectors/billingVersionsSelectors'

import { requestTaxNumber } from './taxFormActions'

interface GetStripeCheckoutURLOptions {
  checkoutType: CheckoutTypes
  source?: CheckoutSources | UpgradeSourceType
  next?: string
  channel?: string
  products?: ProductType[]
  addOns?: AddOnSlug[]
}

export const getStripeCheckoutURL = createAsyncAction(
  'billing/getStripeCheckoutURL',
  async (payload: GetStripeCheckoutURLOptions, { getState, dispatch }) => {
    const { checkoutType, source, next, channel, products, addOns } = payload
    const state = getState()

    const hasTaxNumber = await dispatch(requestTaxNumber(true)).unwrap()
    if (!hasTaxNumber) {
      throw new CancelError()
    }

    const billingVersion = getBillingVersion(state)

    const pathname = getPathname()
    const hash = getHash()
    const search = getSearch()

    const searchParams = new URLSearchParams(search)

    next && searchParams.set('next', encodeURIComponent(next))
    channel && searchParams.set('channel', encodeURIComponent(channel))
    searchParams.set('stripe_type', encodeURIComponent(checkoutType))
    source && searchParams.set('stripe_source', encodeURIComponent(source))

    const returnURL = window.location.origin.concat(pathname, '?', searchParams.toString(), hash)

    const body: CheckoutAndConnectCardRequest & {
      addons?: AddOnSlug[]
    } = {
      return_url: returnURL,
    }

    if (products) {
      body.products = products
    }

    switch (checkoutType) {
      case CheckoutTypes.TRIAL:
        body.is_upgrade_to_pro = true
        body.is_trial_request = true
        break
      case CheckoutTypes.UPGRADE_TO_PRO:
        body.is_upgrade_to_pro = true
        break
      default:
        break
    }

    let redirectURL: string | null = null
    switch (billingVersion) {
      case BillingVersion.BASE: {
        const response = await BillingApi.checkout({ body })
        redirectURL = response.data.redirect_url
        break
      }

      case BillingVersion.FLEX: {
        const request =
          checkoutType === CheckoutTypes.UPGRADE_DATA
            ? billingFlexApi.checkoutAndConnectCard
            : billingFlexApi.checkoutAndSubscribe
        if (addOns) {
          body.addons = addOns
        }

        const response = await request({ body })
        redirectURL = response.data.redirect_url
        break
      }

      default: {
        errorTrackingService.trackError(new Error('Unknown billing version'), {
          extra: { billingVersion },
          fingerprint: 'unknown-billing-version',
        })
        break
      }
    }

    return { redirectURL }
  },
  {
    onBusinessError: (error) => {
      if (BillingFlexApiGuards.isTrialUnavailableError(error)) {
        error.handle()
        alert(error.original_message, 'danger')
      }
      if (isCancelError(error)) {
        error.handle()
      }
    },
  },
)

export const handleStripeRedirect = (url?: string | null) => {
  if (!url) {
    return
  }

  sendStripeMetric(StripeMetricsContext.REDIRECT)
  window.location.href = url
}

export interface StripeCheckoutPayload {
  type?: CheckoutTypes
  source?: CheckoutSources
  products?: ProductType[]
  addOns?: AddOnSlug[]
}

export const redirectToStripe = createAsyncAction(
  'billing/redirectToStripe',
  async (payload: StripeCheckoutPayload, { dispatch, getState }) => {
    sendStripeMetric(StripeMetricsContext.GET_REDIRECT_URL)

    const state = getState()
    const proModalState = getUpgradeRequest(state)
    const hasTrial = isTrialAvailable(state)
    const query = getQuery()
    const { coupon } = getBillingProInfo(state)
    const products = getCurrentAccountProductList(state)
    const type = getStripeCheckoutType(hasTrial, Boolean(coupon))

    const { redirectURL } = await dispatch(
      getStripeCheckoutURL({
        source: payload.source ?? proModalState.source,
        checkoutType: payload.type ?? type,
        products: payload.products ?? products,
        addOns: payload.addOns,
        next: query.next ?? '',
        channel: query.channel ?? '',
      }),
    ).unwrap()

    handleStripeRedirect(redirectURL)
  },
  {
    onBusinessError: (error) => {
      if (BillingFlexApiGuards.isTrialUnavailableError(error)) {
        error.handle()
        alert(error.original_message, 'danger')
      }
      if (isCancelError(error)) {
        error.handle()
      }
    },
  },
)

export const stripeReady = createAction('billing/stripeReady')

interface FetchStripeCheckoutInfoResult {
  info: StripeCheckoutInfo | null
}

export const fetchStripeCheckoutInfo = createAsyncAction<FetchStripeCheckoutInfoResult, string>(
  'billing/fetchStripeCheckoutInfo',
  async (stripeHash) => {
    const response = await BillingApi.getCheckoutInfo({
      query: {
        stripe_hash: stripeHash,
      },
    })

    return response.data
  },
)

interface ConfirmIntentParams {
  stripe: Stripe
  client_secret: string
  type: StripeIntentType
}

interface ConfirmOptionsResult {
  intentId: string
}

export async function confirmIntent({
  stripe,
  client_secret,
  type,
}: ConfirmIntentParams): Promise<ConfirmOptionsResult> {
  let result
  switch (type) {
    case 'setup':
      result = await stripe.confirmSetup({
        clientSecret: client_secret,
        redirect: 'if_required',
      })
      break
    case 'payment':
      result = await stripe.confirmPayment({
        clientSecret: client_secret,
        redirect: 'if_required',
      })
      break
    default:
      throw new Error('Invalid Stripe Confirm Type')
  }

  const { error } = result
  if (error) throw new StripeError()

  if (type === 'setup' && 'setupIntent' in result) {
    return { intentId: result.setupIntent.id }
  }
  if (type === 'payment' && 'paymentIntent' in result) {
    return { intentId: result.paymentIntent.id }
  }
  throw new Error('Unexpected Stripe result structure')
}

export const createCustomer = createAsyncAction<
  StripeConfirmedIntentSchema | undefined,
  StripeCreateCustomerPayload & { stripe: Stripe }
>('billingFlex/createCustomer', async (payload) => {
  const response = await billingFlexApi.createCustomer({
    body: {
      stripe_payment_method_id: payload.stripe_payment_method_id,
      email_billing: payload.email_billing,
    },
  })

  let confirmResult

  if (response.data && response.data.confirm) {
    const { type, client_secret } = response.data.confirm

    confirmResult = await confirmIntent({
      stripe: payload.stripe,
      type,
      client_secret,
    })
  }

  alert(l.translate('Successfully updated'), 'success')
  return confirmResult
})
