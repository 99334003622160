import { z } from 'zod'

import { AvailableCurrencies } from 'common/settings/constants/AvailableCurrencies'

import { AvailableCurrenciesMapSchema } from './AvailableCurrenciesMapSchema'
import { PayPalConfigSchema } from './PayPalConfigSchema'

const StripeAddressSchema = z.object({
  city: z.string().nullable(),
  country: z.string().nullable(),
  line1: z.string().nullable(),
  line2: z.string().nullable(),
  postal_code: z.string().nullable(),
  state: z.string().nullable(),
})

const StripeBusinessProfileSchema = z.object({
  mcc: z.string().nullable(),
  name: z.string().nullable(),
  support_address: z.string().or(StripeAddressSchema).nullable(),
  support_email: z.string().nullable(),
  support_phone: z.string().nullable(),
  support_url: z.string().nullable(),
  url: z.string().nullable(),
})

const StripeControllerSchema = z.object({
  is_controller: z.boolean().nullable().optional(),
  type: z.string().optional(),
})

const StripeExternalAccountsSchema = z.object({
  object: z.string(),
  data: z.array(z.unknown()),
  has_more: z.boolean(),
  total_count: z.number().optional(),
  url: z.string(),
})

const StripeFutureRequirementsSchema = z.object({
  alternatives: z.array(z.unknown()),
  current_deadline: z.number().nullable(),
  currently_due: z.array(z.unknown()),
  disabled_reason: z.string().nullable(),
  errors: z.array(z.unknown()),
  eventually_due: z.array(z.unknown()),
  past_due: z.array(z.unknown()),
  pending_verification: z.array(z.unknown()),
})

const StripeRequirementsSchema = z.object({
  alternatives: z.array(z.unknown()).nullable(),
  current_deadline: z.number().nullable(),
  currently_due: z.array(z.string()).nullable(),
  disabled_reason: z.string().nullable(),
  eventually_due: z.array(z.string()).nullable(),
  past_due: z.array(z.string()).nullable(),
  pending_verification: z.array(z.string()).nullable(),
})

const BacsDebitPaymentsSchema = z
  .object({
    display_name: z.string().nullable(),
    service_user_number: z.string().or(z.number()).nullable(),
  })
  // @todo: https://youtrack.manychat.io/issue/BB-1701/Stripe-integration-settings-serializer-bug
  .or(z.any())

const StripeSettingsSchema = z.object({
  bacs_debit_payments: BacsDebitPaymentsSchema,
  branding: z.object({
    icon: z.string().nullable(),
    logo: z.string().nullable(),
    primary_color: z.string().nullable(),
    secondary_color: z.string().nullable(),
  }),
  card_issuing: z.object({
    tos_acceptance: z.object({
      date: z.number().nullable(),
      ip: z.string().nullable(),
    }),
  }),
  card_payments: z.object({
    statement_descriptor_prefix: z.string().nullable(),
  }),
  dashboard: z.object({
    display_name: z.string().nullable(),
    timezone: z.string().nullable(),
  }),
  payments: z.object({
    statement_descriptor: z.string().nullable(),
    statement_descriptor_kana: z.string().nullable(),
    statement_descriptor_kanji: z.string().nullable(),
  }),
  payouts: z
    .object({
      debit_negative_balances: z.boolean(),
      schedule: z.object({
        delay_days: z.number(),
        interval: z.string(),
      }),
      statement_descriptor: z.string().nullable(),
    })
    .optional(),
  sepa_debit_payments: z.array(z.unknown()),
})

const StripeIntegrationSchema = z.object({
  id: z.string(),
  object: z.string(),
  business_profile: StripeBusinessProfileSchema,
  statement_descriptor: z.string().optional(),
  capabilities: z.array(z.unknown()).or(z.object({})),
  charges_enabled: z.boolean(),
  controller: StripeControllerSchema.optional(),
  country: z.string(),
  default_currency: z.string(),
  details_submitted: z.boolean(),
  email: z.string().nullable(),
  external_accounts: StripeExternalAccountsSchema.optional(),
  future_requirements: StripeFutureRequirementsSchema.nullable(),
  metadata: z.array(z.unknown()),
  payouts_enabled: z.boolean(),
  requirements: StripeRequirementsSchema.nullable(),
  settings: StripeSettingsSchema,
  type: z.string(),
})

export const PaymentsSchema = z.object({
  currency: z.nativeEnum(AvailableCurrencies),
  notify_settings: z.array(z.string()),
  paypal: z.union([z.boolean(), PayPalConfigSchema, z.null()]),
  charge_email_notification: z.boolean().nullable(),
  stripe: StripeIntegrationSchema.nullable(),
  available_currencies: AvailableCurrenciesMapSchema,
  allowed_currencies: z.array(z.nativeEnum(AvailableCurrencies)).or(z.array(z.string())),
  has_connected_system: z.boolean(),
})
