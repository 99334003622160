export const APP_UPDATE = 'APP_UPDATE'
export const APP_UPDATE_CURRENT_ACCOUNT_ID = 'APP_UPDATE_CURRENT_ACCOUNT_ID'
export const APP_UPDATE_CURRENT_ACCOUNT = 'APP_UPDATE_CURRENT_ACCOUNT'
export const APP_RESET_CURRENT_ACCOUNT = 'APP_RESET_CURRENT_ACCOUNT'
export const APP_UPDATE_THREADS_STATS = 'APP_UPDATE_THREADS_STATS'
export const APP_UPDATE_CONNECTED_INTEGRATIONS = 'APP_UPDATE_CONNECTED_INTEGRATIONS'
export const APP_LOAD_EXTENDED_ACCOUNT_DATA_SUCCESS = 'APP_LOAD_EXTENDED_ACCOUNT_DATA_SUCCESS'
export const APP_LOAD_EXTENDED_ACCOUNT_DATA_SUCCESS_RESPONSE =
  'APP_LOAD_EXTENDED_ACCOUNT_DATA_SUCCESS_RESPONSE'
export const APP_APPLY_OPTIN_REQUIRED = 'APP_APPLY_OPTIN_REQUIRED'
export const APP_DROP_OPTIN_REQUIRED = 'APP_DROP_OPTIN_REQUIRED'
export const APP_SET_SMS_EMAIL_OPTIN_ERRORS = 'APP_SET_SMS_EMAIL_OPTIN_ERRORS'
export const APP_DROP_SMS_EMAIL_OPTIN_ERRORS = 'APP_DROP_SMS_EMAIL_OPTIN_ERRORS'
export const APP_APPLY_SMS_EMAIL_OPTIN_REQUIRED = 'APP_APPLY_SMS_EMAIL_OPTIN_REQUIRED'
export const APP_DROP_SMS_EMAIL_OPTIN_REQUIRED = 'APP_DROP_SMS_EMAIL_OPTIN_REQUIRED'
export const APP_APPLY_WHATSAPP_OPTIN_REQUIRED = 'APP_APPLY_WHATSAPP_OPTIN_REQUIRED'
export const APP_DROP_WHATSAPP_OPTIN_REQUIRED = 'APP_DROP_WHATSAPP_OPTIN_REQUIRED'
export const APP_APPLY_TELEGRAM_OPTIN_REQUIRED = 'APP_APPLY_TELEGRAM_OPTIN_REQUIRED'
export const APP_DROP_TELEGRAM_OPTIN_REQUIRED = 'APP_DROP_TELEGRAM_OPTIN_REQUIRED'
export const SUBSCRIBER_ADMIN_CREATE_OR_UPDATE = 'SUBSCRIBER_ADMIN_CREATE_OR_UPDATE'
export const APP_SET_ACTIVE_TAB = 'APP_SET_ACTIVE_TAB'
export const APP_SET_TITLE = 'APP_SET_TITLE'
export const APP_LOAD_FBSDK_REQUEST = 'APP_LOAD_FBSDK_REQUEST'
export const APP_LOAD_FBSDK_SUCCESS = 'APP_LOAD_FBSDK_SUCCESS'
export const APP_CLEAR_PERM_ERROR = 'APP_CLEAR_PERM_ERROR'
export const FORCE_UPDATE_STATE = 'FORCE_UPDATE_STATE'
export const APP_SET_BOT_TIMEZONE = 'APP_SET_BOT_TIMEZONE'
export const APP_RESET_BOT_TIMEZONE = 'APP_RESET_BOT_TIMEZONE'

export const APP_SET_PAGE_NAME = 'APP_SET_PAGE_NAME'

export const READ_ALL_POLICY_ENFORCEMENT_NOTIFICATIONS_FETCH =
  'READ_ALL_POLICY_ENFORCEMENT_NOTIFICATIONS_FETCH'
export const READ_ALL_POLICY_ENFORCEMENT_NOTIFICATIONS_FETCH_RESPONSE =
  'READ_ALL_POLICY_ENFORCEMENT_NOTIFICATIONS_FETCH_RESPONSE'
export const NEW_POLICY_ENFORCEMENT_NOTIFICATION_NOTIFICATION =
  'NEW_POLICY_ENFORCEMENT_NOTIFICATION_NOTIFICATION'
export const READ_POLICY_ENFORCEMENT_NOTIFICATION_NOTIFICATION =
  'READ_POLICY_ENFORCEMENT_NOTIFICATION_NOTIFICATION'

export const CONNECT_FB_CHANNEL = 'CONNECT_FB_CHANNEL'

export const STATIC_VERSION_FETCH = 'STATIC_VERSION_FETCH'
export const STATIC_VERSION_FETCH_REQUEST = 'STATIC_VERSION_FETCH_REQUEST'
export const STATIC_VERSION_FETCH_RESPONSE = 'STATIC_VERSION_FETCH_RESPONSE'
export const STATIC_VERSION_FETCH_ERROR = 'STATIC_VERSION_FETCH_ERROR'

export const PROFILE_SET_DISPLAY_FLAGS_RESPONSE = 'PROFILE_SET_DISPLAY_FLAGS_RESPONSE'
export const CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_RESPONSE =
  'CURRENT_ACCOUNT_USER_PROFILE_SET_DISPLAY_FLAGS_RESPONSE'

export const LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH = 'LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH'
export const LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_REQUEST =
  'LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_REQUEST'
export const LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_RESPONSE =
  'LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_RESPONSE'
export const LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_ERROR =
  'LIST_SUSPECTED_FB_POLICY_OFFENDERS_FETCH_ERROR'

export const FETCH_PREVIEW_REF_URL = 'FETCH_PREVIEW_REF_URL'
export const FETCH_PREVIEW_REF_URL_RESPONSE = 'FETCH_PREVIEW_REF_URL_RESPONSE'

export const SET_PRO_STATUS_BLOCKED = 'SET_PRO_STATUS_BLOCKED'
export const SET_PRO_STATUS_UNBLOCKED = 'SET_PRO_STATUS_UNBLOCKED'

export const UPDATE_SKIP_PREVIEW_ONBOARDING_MODAL = 'UPDATE_SKIP_PREVIEW_ONBOARDING_MODAL'

export const ACCOUNT_SET_DISPLAY_FLAG = 'ACCOUNT_SET_DISPLAY_FLAG'
export const ACCOUNT_SET_DISPLAY_FLAG_RESPONSE = 'ACCOUNT_SET_DISPLAY_FLAG_RESPONSE'
export const ACCOUNT_SET_DISPLAY_FLAG_ERROR = 'ACCOUNT_SET_DISPLAY_FLAG_ERROR'

export const CURRENT_ACCOUNT_UPDATE_ASYNC_BILLING_ACTIONS =
  'CURRENT_ACCOUNT_UPDATE_ASYNC_BILLING_ACTIONS'

export const CURRENT_ACCOUNT_UPDATE_WHATSAPP_CHANNEL = 'CURRENT_ACCOUNT_UPDATE_WHATSAPP_CHANNEL'
