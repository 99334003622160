import { z } from 'zod'

import { EasyBuilderConfigStringTitleSet } from './easyBuilderConfigString'
import { viewSectionFormCaseSchema } from './viewFormSections/caseSchema'
import { viewSectionFormDirectResponseSchema } from './viewFormSections/directResponseSchema'
import { viewSectionFormKeywordsSchema } from './viewFormSections/keywordsSchema'
import { viewSectionFormOpeningMessageSchema } from './viewFormSections/openingMessageSchema'
import { viewSectionFormSelectMediaSchema } from './viewFormSections/selectMediaSchema'
import { EasyBuilderConfigNodeType } from './viewSectionTypes'

export { FormCaseVariant } from './viewFormSections/caseSchema'
export { FormDirectResponseVariant } from './viewFormSections/directResponseSchema'
export { FormCommentVariant } from './viewFormSections/keywordsSchema'
export { FormSelectMediaVariant } from './viewFormSections/selectMediaSchema'

const viewSectionTitleSchema = z.object({
  type: z.literal(EasyBuilderConfigNodeType.TITLE_NODE),
  data: z.object({
    string: z.nativeEnum(EasyBuilderConfigStringTitleSet),
  }),
})

const viewSectionFormSectionSchema = z.object({
  type: z.literal(EasyBuilderConfigNodeType.FORM_SECTION_NODE),
  hasNextButton: z.boolean().optional(),
  data: z.union([
    viewSectionFormSelectMediaSchema,
    viewSectionFormKeywordsSchema,
    viewSectionFormOpeningMessageSchema,
    viewSectionFormDirectResponseSchema,
    viewSectionFormCaseSchema,
  ]),
})

export const viewSectionSchema = z.union([viewSectionTitleSchema, viewSectionFormSectionSchema])

export type ViewSection = z.infer<typeof viewSectionSchema>
export type ViewSectionFormSelectMedia = z.infer<typeof viewSectionFormSelectMediaSchema>
export type ViewSectionFormComment = z.infer<typeof viewSectionFormKeywordsSchema>
export type ViewsectionFormOpeningMessage = z.infer<typeof viewSectionFormOpeningMessageSchema>
export type ViewSectionFormDirectResponse = z.infer<typeof viewSectionFormDirectResponseSchema>
export type ViewSectionFormCase = z.infer<typeof viewSectionFormCaseSchema>
