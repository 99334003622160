import { z } from 'zod'

import { ProErrorSchema } from 'shared/api/common/schemas/errors'
import {
  PathField,
  PathFieldTypes,
  EasyBuilderDmFormLinkButton,
} from 'shared/api/requests/easyBuilder/pathFields'
import { viewSectionSchema } from 'shared/api/requests/easyBuilder/viewSections'

export { PathField }
export type { PathFieldTypes, EasyBuilderDmFormLinkButton }

import { EasyBuilderCampaignType } from './types'

const structureSchema = z.object({})
const easyBuilderStatsSchema = z.object({
  read: z
    .object({
      read_total: z.number().nullish(),
      read_unq: z.number().nullish(),
      read_segment_id: z.string().nullish(),
    })
    .nullish(),

  send: z
    .object({
      sent_total: z.number().nullish(),
      sent_unq: z.number().nullish(),
      sent_segment_id: z.string().nullish(),
    })
    .nullish(),

  delivered: z
    .object({
      delivered_total: z.number().nullish(),
      delivered_unq: z.number().nullish(),
      delivered_segment_id: z.string().nullish(),
    })
    .nullish(),

  opened_link: z
    .object({
      clicked_total: z.number().nullish(),
      clicked_unq: z.number().nullish(),
      clicked_segment_id: z.string().nullish(),
    })
    .nullish(),

  collect_email: z
    .object({
      respondents_total: z.number().nullish(),
      respondents_unq: z.number().nullish(),
      respondents_content_id: z.number().nullish(),
    })
    .nullish(),
})

export enum EasyBuilderFlowStatus {
  ACTIVE = 'active',
  TRIGGER_DRAFT = 'draft',
}

export const pathSchema = z.object({
  path: z.array(z.string()),
  field: z.string(),
  duplicate_of: z.nativeEnum(PathField).nullish(),
  validation: z
    .object({
      required: z.boolean().or(z.string()).nullish(),
      min: z.number().or(z.string()).nullish(),
      max: z.number().or(z.string()).nullish(),
      maxLength: z.number().nullish(),
      minLength: z.number().nullish(),
    })
    .nullish(),
})

export const pathsSchema = z.record(z.nativeEnum(PathField), pathSchema.nullable())
const viewSchema = z.object({ paths: pathsSchema, sections: z.array(viewSectionSchema).optional() })

const responseConfigSchema = z.object({
  campaign_type: z.nativeEnum(EasyBuilderCampaignType),
  campaign_title: z.string(),
  template: z.object({
    version_id: z.number(),
    template_id: z.number(),
  }),
  structure: structureSchema,
  view: viewSchema,
})

const responseFlowSchema = z.object({
  flow_ns: z.string(),
  folder_id: z.number().nullish(),
  campaign_type: z.nativeEnum(EasyBuilderCampaignType),
  stats: easyBuilderStatsSchema,
  flow_name: z.string(),
  status: z.nativeEnum(EasyBuilderFlowStatus),
  structure: structureSchema,
  view: viewSchema,
  widget_id: z.number().nullish(),
})

export const easyBuilderSchemas = {
  getConfig: {
    response: responseConfigSchema,
    query: z.object({ campaign_type: z.nativeEnum(EasyBuilderCampaignType) }),
    path: z.undefined(),
  },
  getById: {
    response: responseFlowSchema,
    query: z.object({ flow_ns: z.string() }),
    path: z.undefined(),
  },
  create: {
    response: responseFlowSchema,
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      structure: structureSchema,
      campaign_type: z.nativeEnum(EasyBuilderCampaignType),
      folder_id: z.number().nullish(),
    }),
    errors: {
      ProError: ProErrorSchema,
    },
  },
  edit: {
    response: responseFlowSchema,
    query: z.undefined(),
    path: z.undefined(),
    request: z.object({
      flow_ns: z.string(),
      structure: structureSchema,
    }),
  },
  setStatus: {
    response: z.object({ flow_ns: z.string(), status: z.nativeEnum(EasyBuilderFlowStatus) }),
    request: z.object({ flow_ns: z.string(), status: z.nativeEnum(EasyBuilderFlowStatus) }),
    query: z.undefined(),
    path: z.undefined(),
    errors: {
      ProError: ProErrorSchema,
    },
  },
}

export type ResponseConfig = z.infer<typeof responseConfigSchema>
export type EasyBuilderFlow = z.infer<typeof responseFlowSchema>
export type EasyBuilderFlowMeta = Omit<EasyBuilderFlow, 'structure' | 'view'>
export type EasyBuilderStructure = z.infer<typeof structureSchema>
export type EasyBuilderPath = z.infer<typeof pathSchema>
export interface PathMapped {
  path: string
  validation: EasyBuilderPath['validation']
  duplicate_of?: PathField | null
}
export type EasyBuilderPaths = z.infer<typeof pathsSchema>
export type EasyBuilderStats = z.infer<typeof easyBuilderStatsSchema>
export type EasyBuilderPathsMap = Record<PathField, PathMapped>

export enum EasyBuilderFormFlowState {
  EDITING = 'editing',
  DISABLED = 'disabled',
}
