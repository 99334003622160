import { z } from 'zod'

import { EasyBuilderConfigStringSectionSet } from '../easyBuilderConfigString'
import { PathField } from '../pathFields'
import { EasyBuilderConfigFormSectionType } from '../viewSectionTypes'

export enum FormCaseVariant {
  PUBLIC_REPLY = 'publicReply',
  AUTO_LIKE_REPLIES = 'autoLikeReplies',
  FOLLOW_UP = 'followUp',
  REQUEST_FOLLOW = 'requestFollow',
  COLLECT_EMAIL = 'collectEmail',
}

const viewSectionFormPublicReplySchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.ADDITION_CASE),
  variant: z.literal(FormCaseVariant.PUBLIC_REPLY),
  isForPro: z.boolean().optional(),
  path: z.object({
    enabled: z.literal(PathField.PUBLIC_REPLY_ENABLED),
    messages: z.literal(PathField.PUBLIC_REPLY_MESSAGES),
  }),
})

const viewSectionFormAutoLikeRepliesSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.ADDITION_CASE),
  variant: z.literal(FormCaseVariant.AUTO_LIKE_REPLIES),
  isForPro: z.boolean().optional(),
  path: z.object({
    enabled: z.literal(PathField.AUTOMATICALLY_LIKE_REPLIES),
  }),
})

const followUpCaseSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.ADDITION_CASE),
  string: z.union([
    z.literal(EasyBuilderConfigStringSectionSet.FOLLOW_UP_CGT),
    z.literal(EasyBuilderConfigStringSectionSet.FOLLOW_UP_SR),
  ]),
  variant: z.literal(FormCaseVariant.FOLLOW_UP),
  isForPro: z.boolean().optional(),
  path: z.object({
    text: z.union([
      z.literal(PathField.DM_FOLLOW_UP_MESSAGE),
      z.literal(PathField.CGT_FOLLOW_UP_MESSAGE),
    ]),
    enabled: z.literal(PathField.FOLLOW_UP_ENABLED),
  }),
})

const requestFollowCaseSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.ADDITION_CASE),
  string: z.union([
    z.literal(EasyBuilderConfigStringSectionSet.REQUEST_FOLLOW_CGT),
    z.literal(EasyBuilderConfigStringSectionSet.REQUEST_FOLLOW_SR),
    z.literal(EasyBuilderConfigStringSectionSet.REQUEST_FOLLOW_PRESELECTED_CGT),
  ]),
  variant: z.literal(FormCaseVariant.REQUEST_FOLLOW),
  toggleHidden: z.boolean().optional(),
  isForPro: z.boolean().optional(),
  path: z.object({
    text: z.literal(PathField.REQUEST_FOLLOW_MESSAGE),
    enabled: z.literal(PathField.REQUEST_FOLLOW_ENABLED),
  }),
})

const collectEmailCaseSchema = z.object({
  displayType: z.literal(EasyBuilderConfigFormSectionType.ADDITION_CASE),
  string: z.union([
    z.literal(EasyBuilderConfigStringSectionSet.COLLECT_EMAILS_CGT),
    z.literal(EasyBuilderConfigStringSectionSet.COLLECT_EMAILS_SR),
  ]),
  variant: z.literal(FormCaseVariant.COLLECT_EMAIL),
  isForPro: z.boolean().optional(),
  path: z.object({
    text: z.literal(PathField.COLLECT_EMAILS_MESSAGE),
    enabled: z.literal(PathField.COLLECT_EMAILS_ENABLED),
  }),
})

export const viewSectionFormCaseSchema = z.union([
  viewSectionFormPublicReplySchema,
  viewSectionFormAutoLikeRepliesSchema,
  followUpCaseSchema,
  requestFollowCaseSchema,
  collectEmailCaseSchema,
])
