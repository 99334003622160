import { z } from 'zod'
import { Icon } from '@manychat/manyui'

export enum QuestionElementType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  TEXT = 'text',
  TEXTAREA = 'textarea',
}

export enum QuestionStyleType {
  STANDARD = 'standard',
  SELECT = 'select',
  STACK = 'stack',
}

export enum QuestionItemType {
  STATIC = 'static',
  INPUT = 'input',
}

export enum QuestionnaireType {
  IG_ACCOUNT_CREATION_QUESTIONNAIRE = 'ig_account_creation_questionnaire',
  COMMON_ACCOUNT_CREATION_QUESTIONNAIRE = 'common_account_creation_questionnaire',
  WHATSAPP_ACCOUNT_CREATION_QUESTIONNAIRE = 'whatsapp_account_creation_questionnaire',
  IG_TT_ACCOUNT_CREATION_QUESTIONNAIRE = 'ig_tt_account_questionnaire',
}

const pageAnswerItemSchema = z.object({
  answer_id: z.string(),
  answer_type: z.nativeEnum(QuestionItemType),
  input: z.string().nullable(),
})

const pageAnswerSchema = z.object({
  question_id: z.string(),
  question_type: z.nativeEnum(QuestionElementType),
  answers: z.array(pageAnswerItemSchema),
})

type IconType = keyof typeof Icon
const iconKeys = Object.keys(Icon) as [IconType, ...IconType[]]

const pageQuestionItemSchema = z.object({
  text: z.string(),
  type: z.nativeEnum(QuestionItemType),
  show_questions: z.array(z.string()).nullish(),
  next_question_id: z.string().nullish(),
  placeholder: z.string().optional(),
  id: z.string(),
  question_id: z.string(),
  status: z.number(),
  is_extension: z.boolean().optional(),
  icon_name: z.enum(iconKeys).or(z.string()).nullish(),
})

const pageQuestionSchema = z.object({
  type: z.nativeEnum(QuestionElementType),
  limit: z.number().nullish(),
  style: z.nativeEnum(QuestionStyleType).optional(),
  header: z.string(),
  is_require: z.boolean(),
  is_visible: z.boolean(),
  description: z.string().nullable(),
  placeholder: z.string().nullable(),
  id: z.string(),
  questionnaire_type_id: z.number(),
  position: z.number(),
  status: z.number(),
  items: z.array(pageQuestionItemSchema),
})

const savePageAnswersRequestSchema = z.object({
  answer: z.array(pageAnswerSchema),
  type: z.nativeEnum(QuestionnaireType),
})

const pageQuestionsResponseSchema = z.object({
  questions: z.array(pageQuestionSchema),
})

const UserAnswerItemSchema = z.object({
  answer_id: z.string().nullable(),
  answer_type: z.string(),
  input: z.string().nullable(),
})

const UserAnswerSchema = z.object({
  question_id: z.string(),
  question_type: z.string(),
  answers: z.array(UserAnswerItemSchema),
})

export const questionnaireApiSchemas = {
  getPageQuestions: {
    path: z.undefined(),
    query: z.object({
      type: z.string(),
    }),
    response: pageQuestionsResponseSchema,
  },
  savePageAnswers: {
    path: z.undefined(),
    query: z.undefined(),
    response: z.undefined(),
    request: savePageAnswersRequestSchema,
  },
  saveUserAnswers: {
    path: z.undefined(),
    query: z.undefined(),
    response: z.undefined(),
    request: z.object({
      type: z.string(),
      answer: z.array(UserAnswerSchema),
    }),
  },
}
export type QuestionsResponse = z.infer<typeof pageQuestionsResponseSchema>
export type Question = z.infer<typeof pageQuestionSchema>
export type SubQuestion = z.infer<typeof pageQuestionItemSchema>

export type Answer = z.infer<typeof pageAnswerSchema>
export type AnswerItem = z.infer<typeof pageAnswerItemSchema>
